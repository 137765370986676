import { Fragment } from "react";
import autoRestrito from "../../images/sabemi-produto/auto-restrito.png";
import funeral from "../../images/sabemi-produto/funeral.png";
import invalidez from "../../images/sabemi-produto/invalidez.png";
import morteAcidental from "../../images/sabemi-produto/morte-acidental.png";
import protecaoPessoal from "../../images/sabemi-produto/protecao-pessoal.png";
import residentalEletroassist from "../../images/sabemi-produto/residencial-eletroassist.png";
import sorteio from "../../images/sabemi-produto/sorteio.png";
import option1 from "../../images/option1.png";
import option2 from "../../images/option2.png";

import { ButtonFooter, ButtonHeader, Container } from "./styles";

type IModalProps = {
  selected_product: string;
  onlyText?: boolean;
  handleClick?: any;
};

export const PlanDetail: React.FC<IModalProps> = (props: IModalProps) => {
  const { selected_product, handleClick } = props;

  return (
    <Fragment>
      <Container>
        <div>
          <header>
            {selected_product === "Torcedor Protegido" && (
              <div>
                <img src={option1} alt="Torcedor Protegido" />
                <p>
                  <h3>Torcedor Protegido</h3>
                  R$ 14,90<span>/mês</span>
                </p>
                {handleClick && (
                  <ButtonHeader
                    id="option1"
                    onClick={() => handleClick("Torcedor Protegido")}
                  >
                    Comprar
                  </ButtonHeader>
                )}
              </div>
            )}
            {selected_product === "Torcedor Protegido Plus" && (
              <div>
                <img src={option2} alt="Torcedor Protegido Plus" />
                <p>
                  <h3>Torcedor Protegido Plus</h3>
                  R$ 24,90<span>/mês</span>
                </p>
                {handleClick && (
                  <ButtonHeader
                    id="Torcedor Protegido Plus"
                    onClick={() => handleClick("Torcedor Protegido Plus")}
                  >
                    COMPRAR
                  </ButtonHeader>
                )}
              </div>
            )}
          </header>
          <main>
            <div className="itens">
              <h4>2 coberturas</h4>
              <div className="row-item">
                <i>
                  <img src={morteAcidental} alt="Morte Acidental" />
                </i>
                <div className="info">
                  <h3>Morte Acidental</h3>
                  <p>
                    Indenização para seus beneficiários em caso de falecimento
                    por acidente coberto.
                  </p>
                </div>
                {selected_product === "Torcedor Protegido" && (
                  <div className="price-info">
                    R$<strong>10</strong>mil
                  </div>
                )}
                {selected_product === "Torcedor Protegido Plus" && (
                  <div className="price-info">
                    R$<strong>20</strong>mil
                  </div>
                )}
              </div>
              <div className="row-item">
                <i>
                  <img src={invalidez} alt="Morte Acidental" />
                </i>
                <div className="info">
                  <h3>
                    Invalidez Permanente Total ou Parcial por Acidente (IPA)
                  </h3>
                  <p>
                    Garante o pagamento de indenização ao próprio Segurado,
                    relativa a perda, redução ou impotência funcional
                    definitiva, total ou parcial, de um membro ou órgão por
                    lesão física, causada por acidente pessoal coberto.
                  </p>
                </div>
                {selected_product === "Torcedor Protegido" && (
                  <div className="price-info">
                    R$<strong>10</strong>mil
                  </div>
                )}
                {selected_product === "Torcedor Protegido Plus" && (
                  <div className="price-info">
                    R$<strong>20</strong>mil
                  </div>
                )}
              </div>
            </div>
            {selected_product === "Torcedor Protegido" && (
              <h4>3 assistências</h4>
            )}
            {selected_product === "Torcedor Protegido Plus" && (
              <h4>4 assistências</h4>
            )}
            <div>
              <div className="row-item">
                <i>
                  <img src={funeral} alt="Funeral " />
                </i>
                <div className="info">
                  <h3>Funeral Individual</h3>
                  <p>
                    Garante a prestação do serviço de Assistência Funeral, até o
                    valor de R$ 3.000,00. OS trâmites do funeral do Segurado são
                    providenciados de forma rápida e sem burocracias. A
                    assistência funeral compreende somente a prestação do
                    serviço de funeral, não estando disponível o reembolso das
                    despesas.
                  </p>
                </div>
              </div>
              <div className="row-item">
                <i>
                  <img src={protecaoPessoal} alt="Proteção Pessoal" />
                </i>
                <div className="info">
                  <h3>Proteção Pessoal</h3>
                  <p>
                    Este serviço oferece ajuda em caso de acidente pessoal,
                    assalto, agressão, roubo ou furto envolvendo o Segurado, seu
                    veículo, sua residência e ferimentos pessoais decorrentes
                    desses. Tais como: Remoção médica inter hospitalar,
                    informações sobre bloqueio de aparelhos celulares e cartões
                    de crédito, entre outros.
                  </p>
                </div>
              </div>
              <div className="row-item">
                <i>
                  <img src={autoRestrito} alt="Auto Restrito" />
                </i>
                <div className="info">
                  <h3>Auto Restrito</h3>
                  <p>
                    Assistência com auto socorro o envio de mecânico ou guincho
                    (raio de até 100km) contempla também troca de pneus ou
                    reboque até borracheiro mais próximo para reparo dos pneus.
                  </p>
                </div>
              </div>
              {selected_product === "Torcedor Protegido Plus" && (
                <div className="row-item">
                  <i>
                    <img
                      src={residentalEletroassist}
                      alt="Residencial Eltroassist"
                    />
                  </i>
                  <div className="info">
                    <h3>Residencial EletroAssist</h3>
                    <p>
                      Assistência completa 24h para emergências domésticas, como
                      chaveiro, eletricista, encanador, vidraceiro, etc. Além de
                      consertos de linha branca (eletrodomésticos cobertos) e
                      marrom (eletroeletrônicos cobertos) com pagamento do custo
                      de mão de obra e peças de até R$ 300,00.
                    </p>
                  </div>
                </div>
              )}
              <h4>Benefícios</h4>
              <div className="row-item">
                <i>
                  <img src={sorteio} alt="Auto Sorteio" />
                </i>

                <div className="info">
                  <h3>
                    Sorteio mensal de{" "}
                    {selected_product === "Torcedor Protegido"
                      ? "R$ 5MIL"
                      : "R$ 10MIL "}{" "}
                    para o segurado e{" "}
                    {selected_product === "Torcedor Protegido"
                      ? "R$ 5MIL"
                      : "R$ 10MIL "}{" "}
                    para o Sporting Club Ceará
                  </h3>
                  <p>
                    Com o pagamento do seguro em dia, o segurado e o Sporting
                    Club Ceará concorrerá a sorteios todos os meses pela Loreria
                    Federal no último sábado de cada mês.
                  </p>
                </div>
              </div>

              {selected_product === "Torcedor Protegido" && (
                <div className="cta">
                  <p>
                    R$ 14,90<span>/mês</span>
                  </p>
                  {handleClick && (
                    <ButtonFooter
                      id="option1"
                      onClick={() => handleClick("Torcedor Protegido")}
                    >
                      COMPRAR
                    </ButtonFooter>
                  )}
                </div>
              )}

              {selected_product === "Torcedor Protegido Plus" && (
                <div className="cta">
                  <p>
                    R$24,90<span>/mês</span>
                  </p>
                  {handleClick && (
                    <ButtonFooter
                      id="option2"
                      onClick={() => handleClick("Torcedor Protegido Plus")}
                    >
                      COMPRAR
                    </ButtonFooter>
                  )}
                </div>
              )}

              <div
                style={{
                  margin: "0 auto",
                  fontSize: "12px",
                  textAlign: "center",
                  padding: "12px",
                }}
              >
                Este seguro é garantido pela Sabemi Seguradora S/A, CNPJ
                87.163.234/0001-38. Registro Susep Seguro AP nº 001-02162/94. A
                aceitação do seguro estará sujeita à análise do risco. O
                registro deste plano na SUSEP não implica, por parte da
                Autarquia, incentivo ou recomendação a sua comercialização.
                Sorteios em dinheiro lastreados por Título de Capitalização, da
                modalidade incentivo, emitidos pela KOVR Capitalização CNPJ
                93.202.448/0001-79 e aprovado pela SUSEP sob o Registro SUSEP
                15414.618473/2020-25.
              </div>
            </div>
          </main>
        </div>
      </Container>
    </Fragment>
  );
};
