import styled from "styled-components";

import iconSalary from "../../images/dollar-sign.svg";
import iconExposed from "../../images/briefcase.svg";

import iconUser from "../../images/user.svg";
import iconUserInactive from "../../images/user-inactive.svg";
import iconTwoUser from "../../images/user-two.svg";
import iconTwoUserInactive from "../../images/user-two-inactive.svg";
import iconMarried from "../../images/married.svg";
import iconMarriedInactive from "../../images/married-inactive.svg";
import iconArrowPurple from "../../images/arrow-purple.svg";
export const InputLabel = styled.label``;
export const InputRowStyled = styled.div`
  position: relative;
  span {
    background: url(${(props: { icon?: string }) => props.icon}) no-repeat 0;
    position: absolute;
    bottom: 10px;
    left: 5px;
    background-size: 18px;
    width: 25px;
    height: 25px;
  }
`;

export const InputStyled = styled.input`
  display: block;
  width: 100%;
  margin: 0 auto;
  border: 0;
  border-bottom: 2px solid #000000;
  padding: 11px 35px;
  font-size: 20px;
  margin-bottom: 25px;
`;
export const MyDataStyled = styled.div`
  //background: #fff;
  .introNav {
    margin-bottom: 0%;

    h1 {
      margin-bottom: 0;
    }
  }

  h2 {
    text-align: center;
    font-size: 30px;
    color: #000000;
    font-weight: normal;
    max-width: 492px;
    margin: 0 auto;
    line-height: 40px;

    @media (max-width: 600px) {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    text-align: center;
    color: #656565;
    margin-bottom: 50px;
    line-height: 32px;
    max-width: 492px;
    margin: 20px auto;
    padding: 10px;

    @media (max-width: 600px) {
      padding: 0 15px;
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

export const FormStyled = styled.form`
  max-width: 325px;
  margin: 0 auto;

  @media (max-width: 600px) {
    max-width: 100%;
    margin: 0 auto;
    padding: 15px;
  }

  select {
    display: block;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #000000;
    padding: 11px 35px;
    font-size: 20px;
    margin-bottom: 25px;

    &[class="salary"] {
      background: url(${iconSalary}) no-repeat 0;
      background-size: 20px 20px;
    }
    &[class="exposed"] {
      background: url(${iconExposed}) no-repeat 0;
      background-size: 20px 20px;
    }
  }

  input {
    display: block;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #000000;
    padding: 11px 35px;
    font-size: 20px;
    margin-bottom: 25px;
    background: #fff;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: rgb(0 0 0 / 60%);
    }
    :-ms-input-placeholder {
      color: rgb(0 0 0 / 60%);
    }

    &:focus {
      outline: none;
      border-color: rgb(0 0 0 / 30%);
    }

    &[type="submit"] {
      margin-top: 59px;
      border: 2px solid #000;
      border-radius: 12px;
      position: relative;
      background: url(${iconArrowPurple}) no-repeat 290px;
      color: #000000;
      cursor: pointer;
    }
  }

  fieldset {
    margin: 10px 0;
    border: 0;
    display: flex;
    padding: 0;
    justify-content: space-between;
  }
  .check-genre input {
    background: none;
    border: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    display: block;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
    text-align: center;
  }
  .check-genre input:checked {
    border-color: #000000;

    &:after {
      background-color: #000000 !important;
    }
  }

  .check-genre input:active {
    border-color: #000000;
  }

  .check-genre {
    background: none;
    border-radius: 12px;
    font-size: 14px;
    line-height: 10px;
    padding: 62px 7px 16px 7px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    transition: all 0.3s;
    height: auto;
    cursor: pointer;
    position: relative;
    outline: none;
    color: rgb(0 0 0 / 60%);
    border: 0;
    height: 100px;
    width: 32%;

    &:has(input:checked) {
      font-weight: bold;
      color: #000000 !important;
    }

    &:first-child input:checked:after {
      background: url(${iconUser}) no-repeat center center;
    }

    &:first-child input:after {
      background: url(${iconUserInactive}) no-repeat center center;
      background-size: 11.35px;
    }

    &:nth-child(2) input:checked:after {
      background: url(${iconTwoUser}) no-repeat center center;
      background-size: 14px;
    }

    &:nth-child(2) input:after {
      background: url(${iconTwoUserInactive}) no-repeat center center;
      background-size: 14px;
    }

    &:last-child input:checked:after {
      background: url(${iconMarried}) no-repeat center center;
      background-size: 14px;
    }

    &:last-child input:after {
      background: url(${iconMarriedInactive}) no-repeat center center;
      background-size: 14px;
    }
  }

  .check-genre input {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99;
    top: 0px;
  }

  .check-genre input:after {
    position: absolute;
    content: "";
    width: 32px;
    left: 31%;
    top: 21px;
    height: 32px;
    border-radius: 19px;
    border: 2px solid rgb(0 0 0 / 60%);
  }
`;
