import styled from "styled-components";
import iconPadLock from "../../images/padlock.svg";
import iconMail from "../../images/mail-purple.svg";
import desktop from "../../images/desktop.jpg";
import iconSecurity from "../../images/security.svg";
import iconHeart from "../../images/heart-purple.svg";
import iconHeartWhite from "../../images/heart-white.svg";

export const ButtonLink = styled.div`
  border: none;
  background-color: inherit;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;

  text-decoration: underline;
`;

export const InputPayment = styled.div`
  max-width: 250px;
  margin: 0 auto;
  span {
    display: block;
    font-size: 12px;
    text-align: right;
  }
  input {
    display: block;
    margin: 0 auto;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #000000;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 15px;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: rgb(0 0 0 / 60%);
      font-size: 16px;
    }
    :-ms-input-placeholder {
      color: rgb(0 0 0 / 60%);
      font-size: 16px;
    }

    &:focus {
      outline: none;
      border-color: rgb(0 0 0 / 30%);
    }
  }
`;

export const SelectPayment = styled.div`
  select {
    display: block;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    border: 0;
    border-bottom: 2px solid #000000;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 15px;
    background: none;
  }
`;
export const ButtonPayment = styled.button`
  border: 0;
  background: none;
  width: 160px;
  margin: 10px auto;
  display: block;
  margin-top: 20px;
  padding: 10px;
  border: 2px solid #000;
  border-radius: 12px;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: #000;
    color: #fff;
  }
`;
export const PaymentStyled = styled.div`
  //background:url(${desktop}) top center no-repeat;

  .introNav {
    margin-bottom: 0%;

    h1 {
      margin-bottom: 0;
    }
  }
  .box-section {
    background: #fff;
    padding: 10px;
    padding-bottom: 0;
    margin: 0 auto;

    min-height: 300px;
    @media (max-width: 600px) {
      padding: 0 15px;
    }
    /*    .account-dv {
      width: 100px;
      margin-left: 5px;

      text-align: center;
    } */
    /* .account {
      margin-left: 5px;

      text-align: center;
    } */
    /*   .agency {
      width: 100px;
      margin-left: 5px;

      text-align: center;
    }
 */
    /* .account-group {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
    } */
  }

  h2 {
    text-align: center;
    font-size: 30px;
    color: #000000;
    font-weight: normal;
    max-width: auto;
    margin: 0 auto;
    line-height: 40px;

    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 25px;
      padding: 0 15px;
    }
  }

  .box-card {
    padding: 0 15px;
  }

  .credit-card {
    width: 463px;
    height: 281px;
    border: 2px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 18px;
    margin: 58px auto;
    padding: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 600px) {
      width: 100%;
      padding: 20px;
      height: 220px;
      margin: 0;
      margin-bottom: 30px;
    }

    input[type="text"] {
      border: 0;
      border-bottom: 2px solid #000000;
      width: 100%;
      max-width: 317px;
      margin-bottom: 20px;
      font-size: 15px;
      padding-bottom: 8px;
    }

    &:after {
      content: "";
      display: block;
      background: url(${iconPadLock}) no-repeat;
      width: 40px;
      height: 40px;
      top: 20px;
      right: 5px;
      position: absolute;

      @media (max-width: 600px) {
        right: 0;
        background-size: 14px;
      }
    }
  }

  .info-card {
    max-width: 317px;
    justify-content: space-between;
    input[type="text"],
    select,
    input[type="number"] {
      border: 0;
      border-bottom: 2px solid #000000;
      width: 29%;
      max-width: 317px;
      margin-bottom: 0px;
      font-size: 15px;
      padding-bottom: 8px;
      color: rgb(0 0 0 / 30%);
    }
  }

  .data-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .data-list fieldset {
    width: 49%;
    border: 2px solid #000000;
    border-radius: 12px;
    padding: 50px;
    margin-bottom: 15px;

    @media (max-width: 600px) {
      width: 100%;
      word-break: break-all;
      padding: 30px;
    }

    h5 {
      margin-top: 0;
      font-size: 26px;
      color: #000000;

      @media (max-width: 600px) {
        max-width: 90%;
        word-break: break-word;
        line-height: 35px;
        margin-bottom: 20px;
      }
    }

    p {
      font-size: 20px;
      color: #000;
      display: flex;
      align-items: center;

      i {
        width: 30px;
        text-align: center;
        margin-right: 10px;
      }
    }
  }

  .numbers-card input {
    width: 75px;
    height: 33px;
    background: #efefef;
    border-radius: 5px;
    border: 0;
    margin: 3px 3px 20px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  label {
    font-size: 10px;
    color: #000000;
  }

  .data-login {
    max-width: 352px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: 600px) {
      padding: 0 25px;
      margin-bottom: 10px;
    }

    input {
      border: 0;
      border-bottom: 2px solid #000000;
      width: 100%;
      max-width: 317px;
      margin-bottom: 8px;
      font-size: 18px;
      padding-bottom: 8px;
      padding-left: 35px;
      color: #000000;
      background: url(${iconMail}) no-repeat 0 2px;

      ::placeholder {
        opacity: 0.6;
        color: #000000;
      }
    }
  }

  .revision-order {
    background: #fff;
    padding: 20px 0 30px;

    @media (max-width: 600px) {
      padding: 0 10px;
    }

    h3 {
      color: #000000;
      font-size: 30px;
      padding: 0 25%;
      text-align: center;
      margin-top: 0;
      margin-bottom: 60px;

      @media (max-width: 600px) {
        padding: 80px 30px 0;
        font-size: 24px;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: baseline;

      li {
        margin-left: 20px;

        font-size: 18px;
        color: #000000;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        flex-direction: column;
        @media (max-width: 600px) {
          width: 100%;
        }

        p {
          width: 140px;
          margin-left: 0 auto;
          padding: 0;
          span {
            color: #656565;
            font-weight: 600;
            display: block;
          }
        }
        img {
          width: 140px;
        }
      }
    }
  }

  .protocolo {
    text-align: center;
    padding: 20px 0 80px;

    @media (max-width: 600px) {
      padding: 20px 10px 80px;
    }
    p {
      color: #888888;
      font-size: 18px;

      @media (max-width: 600px) {
        margin: 0;
      }

      strong {
        font-weight: bold;
        font-size: 20px;
      }
    }
  }

  h4 {
    font-size: 25px;
    text-align: center;
    color: #676767;
    margin-bottom: 25px;

    @media (max-width: 600px) {
      padding: 0 20px;
    }
  }

  .coverage-list {
    margin-bottom: 70px;

    p {
      font-size: 18px;
    }
  }
  .checkbox {
    max-width: 354px;
    margin: 0 auto;
    text-align: center;

    @media (max-width: 600px) {
      text-align: center;
      padding: 0 50px;
    }
  }
  .privacy {
    max-width: 354px;
    margin: 4px auto;
    text-align: center;

    label {
      text-align: justify;
      text-justify: inter-word;
      display: flex;

      input {
        margin: 4px;
      }
      p {
        margin: 0 auto;
      }
    }

    h6 {
      text-align: center;
      margin: 60px 0 0 0;
      padding: 10px;
      font-size: 14px;
      color: #000000;
      font-weight: bold;
      background: url(${iconSecurity}) no-repeat 47px;

      @media (max-width: 600px) {
        background: url(${iconSecurity}) no-repeat 0px;
      }
    }

    p {
      color: #676767;
      font-size: 12px;
      margin-left: 4px;
      text-align: justify;
      text-justify: inter-word;

      @media (max-width: 600px) {
        margin: 0;
        padding-bottom: 80px;
      }

      a {
        color: #676767;
        text-decoration: underline;
      }
    }
  }

  footer {
    @media (max-width: 600px) {
      margin: 0 20px;
      display: block;
      text-align: center;
      margin-bottom: 90px;

      .powered {
        position: absolute;
        width: 100%;
        left: 0;
        margin-top: 20px;
      }
    }
  }
`;

export const PaymentNotAcceptedStyled = styled.div`
  background: #000000;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: block;
    padding: 0 15px;
  }

  footer {
    border-bottom: 5px solid rgb(248 248 248 / 30%);

    p {
      @media (max-width: 600px) {
        color: #000000;
      }
    }
  }

  .back-link {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 13px;
    cursor: pointer;
    opacity: 1;
    transition: all 0.25s;

    img {
      margin-right: 5px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .box-status {
    max-width: 530px;
    margin: 15px auto;
    color: #fff;
    text-align: center;

    h1 {
      font-size: 35px;
      margin: 25px auto;
      max-width: 375px;
      text-align: center;
    }

    p {
      font-size: 25px;
      line-height: 34px;
    }
  }
`;

export const PaymentPreAcceptedStyled = styled.div`
  text-align: center;
  header {
    background: #000000;
    padding: 0 30px;
  }

  footer {
    position: relative;
    @media (max-width: 600px) {
      margin-left: 30px;
      margin-right: 30px;
      margin-bottom: 60px;

      .logo {
        margin-bottom: 10px;
        margin: 10px auto;
      }

      p {
        color: #000000;
      }

      .powered {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 83px;
      }
    }
  }

  .box-status {
    max-width: 540px;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    padding: 140px 0 70px 0;

    @media (max-width: 600px) {
      padding: 80px 0 70px 0;
    }

    h1 {
      font-size: 35px;
      margin: 25px auto;
      max-width: 420px;
      text-align: center;

      @media (max-width: 600px) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    p {
      font-size: 20px;
      line-height: 34px;
      max-width: 80%;
      margin: 0 auto;
      margin-bottom: 30px;
      line-height: 27px;
    }
  }

  main {
    padding: 70px 30px;
    max-width: 424px;
    margin: 0 auto;

    @media (max-width: 600px) {
      padding: 40px 30px;
    }

    p {
      font-size: 20px;
    }

    h3 {
      font-size: 25px;
      color: #000000;
      max-width: 323px;
      margin: 0 auto;
      margin-bottom: 25px;
      position: relative;

      &:after {
        content: "";
        display: block;
        background: url(${iconHeart}) no-repeat;
        padding: 13px;
        position: absolute;
        right: 41px;
        top: 42px;

        @media (max-width: 600px) {
          display: none;
        }
      }
    }
  }
`;

export const PaymentApprovedStyled = styled.div`
  background: #000000;
  text-align: center;
  padding: 0 30px;

  footer {
    border-bottom: 5px solid rgb(248 248 248 / 30%);
    margin-bottom: 66px;

    @media (max-width: 600px) {
      margin-bottom: 45px;
      flex-direction: column;
      position: relative;

      .logo {
        margin-bottom: 10px;
      }

      .powered {
        position: relative;
        width: 100%;
        text-align: center;
        top: 83px;
      }

      p {
        font-size: 14px !important;
      }
    }

    p {
      @media (min-width: 600px) and (max-width: 1366px) {
        font-size: 14px !important;
      }
    }

    .logo {
      @media (min-width: 600px) and (max-width: 1366px) {
        margin-right: 10px;
      }
    }
  }

  .box-status {
    max-width: 540px;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    padding: 140px 0 70px 0;

    @media (max-width: 600px) {
      padding: 100px 0 0px 0;
    }

    h1 {
      font-size: 40px;
      margin: 25px auto;
      max-width: 420px;
      text-align: center;
      line-height: 54px;
      margin-bottom: 15px;

      @media (max-width: 600px) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    p {
      font-size: 20px;
      line-height: 34px;
      max-width: 80%;
      margin: 0 auto;
      margin-bottom: 30px;
      line-height: 27px;
    }
  }

  button {
    width: 100%;
    max-width: 354px;
    margin: 0 auto;
    height: 54px;
    border: 2px solid #fff;
    border-radius: 12px;
    background: none;
    color: #fff;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.35s;

    &:hover {
      background: #fff;
      color: #000000;
    }
  }

  .box-approved {
    max-width: 424px;
    margin: 0 auto;
  }

  main {
    padding: 15px 0 30px;

    p {
      font-size: 20px;
      color: #fff;
    }

    h3 {
      font-size: 25px;
      color: #fff;
      max-width: 323px;
      margin: 0 auto;
      margin-bottom: 25px;
      position: relative;

      &:after {
        content: "";
        display: block;
        background: url(${iconHeartWhite}) no-repeat;
        padding: 13px;
        position: absolute;
        right: 41px;
        top: 42px;

        @media (max-width: 600px) {
          display: none;
        }
      }
    }
  }

  .protocol {
    padding-top: 38px;

    @media (max-width: 600px) {
      padding-top: 0;
    }

    p {
      margin-bottom: 3px;
      margin-top: 5px;
    }
    strong {
      color: #fff;
      font-weight: bold;
      font-size: 35px;
      word-break: break-all;
    }
  }

  .msg-mail {
    margin-bottom: 65px;

    @media (max-width: 600px) {
      margin-top: 50px;
    }
  }

  .canals {
    margin-top: 40px;
    margin-bottom: 100px;

    p {
      margin-bottom: 30px;
    }
  }
`;
