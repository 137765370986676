/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect } from "react";
import { GlobalStyle } from "../../styles/global.styled";
import { RegisterContent, SectionForm } from "../form/form.page.styled";
import Register from "../../Components/Register/register.component";
import Intro from "../../Components/Intro/intro.component";
import { MobileView } from "react-device-detect";
import Sabemi from "../../Components/Sabemi";
import { useHistory } from "react-router";
import { useLead } from "../../hooks/lead";

function App() {
  const [forYou, setForYou] = useState<boolean>(true);
  const history = useHistory();
  const { handleLocation } = useLead();

  useEffect(() => {
    let locationSearch = history.location.search.replace("?", "");
    let queryParams: any = {};
    for (const param of locationSearch.split("&")) {
      let paramSplit = param.split("=");
      queryParams[paramSplit[0]] = paramSplit[1];
    }
    async function requestLocation() {
      await handleLocation(queryParams);
    }

    requestLocation();
  }, []);

  return (
    <Fragment>
      <GlobalStyle />
      <RegisterContent forYou={forYou}>
        <Intro setForYou={setForYou} />
        <SectionForm>
          <Register />
        </SectionForm>
      </RegisterContent>
      <MobileView>
        <Sabemi enableFooter disableLogo />
      </MobileView>
    </Fragment>
  );
}

export default App;
