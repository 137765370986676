import sabemiPreto from "../../images/sabemi-preto.png";
import logo from "../../images/brasao-ceara.png";

import styled from "styled-components";
import FooterLinks from "../FooterLinks/footerLinks.component";

const Container = styled.div`
  background: rgb(255, 255, 255);
  margin: 0 auto;
  max-width: 1024px;
  padding: 10px;
`;
const Footer = styled.div`
  background: rgb(255, 255, 255);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  flex-wrap: nowrap;
  @media (max-width: 1170px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 600px) {
    display: block;
    text-align: center;
  }
  img {
    padding: 10px;

    @media (max-width: 600px) {
      margin: 0 auto;
      display: block;
    }
  }
`;
const LogoSabemi = styled.div`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  flex-wrap: nowrap;
  letter-spacing: -1px;
  padding: 10px;
  white-space: nowrap;

  @media (max-width: 600px) {
    display: block;
    text-align: center;
  }
  .box {
    padding: 5px;
  }

  .channel {
    font-size: 10px;
    font-weight: bold;
  }

  .contact {
    font-size: 16px;
    font-weight: bold;

    a,
    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
      color: inherit;
    }
  }
`;
const Sabemi = (props: any) => {
  const { disableLogo } = props;
  return (
    <Container>
      {!!disableLogo && <FooterLinks />}
      <Footer>
        {!disableLogo && <img height="90px" src={logo} alt="Ceará" />}

        <img height="90px" src={sabemiPreto} alt="Sabemi" />

        <LogoSabemi>
          <div className="box">
            <div className="channel">Central de Atendimento</div>
            <div className="contact">0800 880 1900 </div>
          </div>
          <div className="box">
            <div className="channel">Ouvidoria</div>
            <div className="contact">0800 880 1999</div>
          </div>
          <div className="box">
            <div className="channel">SUSEP</div>
            <div className="contact">0800 021 8484</div>
          </div>
          <div className="box">
            <div className="channel">Canal de Denúncias </div>
            <div className="contact">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.contatoseguro.com.br/sabemi"
              >
                Alô Sabemi
              </a>
            </div>
          </div>
          <div className="box">
            <div className="channel">E-mail</div>
            <div className="contact">sac@sabemi.com.br</div>
          </div>
        </LogoSabemi>
        {!disableLogo && (
          <p>
            Powered by <strong>Bria</strong>
          </p>
        )}
      </Footer>
    </Container>
  );
};

export default Sabemi;
