import { Fragment } from "react";
import { GlobalStyle } from "../../styles/global.styled";
import PackageNav from "../../Components/PackageNav/packageNav.component";
import { PlansStyled } from "./plans.styled";
import iconFamily from "../../images/family.svg";
import iconFuneral from "../../images/funeral.svg";
const PlansPage = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <div className="container">
        <PlansStyled>
          <PackageNav
            lead={""}
            color="#fff"
            title='O seguro ideal é aquele que você escolhe.
Selecione as "<strong>coberturas</strong> desejadas:'
            active="Planos"
          />

          <section>
            <h3>
              Agora, selecione as <strong>assistências</strong> que deseja
              incluir:
            </h3>
          </section>

          <div className="row">
            <div className="col">
              <div className="tabs">
                <div className="tab">
                  <input type="radio" id="rd1" name="rd" />
                  <label className="tab-label" htmlFor="rd1">
                    <p>
                      <i>
                        <img src={iconFamily} alt="" />
                      </i>
                      Família<span>2 selecionados</span>
                    </p>
                  </label>
                  <div className="tab-content">
                    <fieldset>
                      <label>
                        <img src={iconFuneral} alt="" />
                        Assistência funeral
                      </label>
                      <input type="checkbox" id="radio" />
                    </fieldset>
                    <fieldset>
                      <label>Cartão Alimentação</label>
                      <input type="checkbox" id="radio" />
                    </fieldset>
                    <fieldset>
                      <label>Proteção pessoal</label>
                      <input type="checkbox" id="radio" />
                    </fieldset>
                    <fieldset>
                      <label>Cesta bebê e mamãe</label>
                      <input type="checkbox" id="radio" />
                    </fieldset>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd2" name="rd" />
                  <label className="tab-label" htmlFor="rd2">
                    Assistência pet
                  </label>
                  <div className="tab-content">
                    <fieldset>
                      <label>Proteção pessoal</label>
                      <input type="checkbox" id="radio" />
                    </fieldset>
                    <fieldset>
                      <label>Cesta bebê e mamãe</label>
                      <input type="checkbox" id="radio" />
                    </fieldset>
                  </div>
                </div>
                <div className="tab">
                  <input type="radio" id="rd3" name="rd" />
                  <label htmlFor="rd3" className="tab-close">
                    Close others &times;
                  </label>
                </div>
              </div>
            </div>
          </div>
        </PlansStyled>
      </div>
    </Fragment>
  );
};

export default PlansPage;
