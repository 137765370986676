import React, { Fragment, useState } from "react";
import { GlobalStyle, Container } from "../../styles/global.styled";
import PackageNav from "../../Components/PackageNav/packageNav.component";
import { MyDataStyled, FormStyled, InputRowStyled } from "./mydata.styled";
import { useHistory } from "react-router";
import MaskedInput from "react-text-mask";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Utils } from "../../utils";
import iconCPF from "../../images/cpf.svg";
import axios from "axios";
import { useLead } from "../../hooks/lead";
import iconName from "../../images/name.svg";
import iconCEP from "../../images/map.svg";
import iconAdress from "../../images/adress.svg";
import iconLocation from "../../images/map-pin.svg";
import iconAdressComp from "../../images/adresscomp.svg";
import Sabemi from "../../Components/Sabemi";
const MyDataPage = (props: any) => {
  const history = useHistory();
  //const lead = props.location.state.lead;
  const { lead, putLead } = useLead();
  const MySwal = withReactContent(Swal);
  const [address, setAddress] = useState({} as any);
  const [formData, setFormData] = useState({
    address_street: lead.address_street,
  } as any);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    //console.log(formData);
  }, []);

  const alertOpen = (msg: any) => {
    MySwal.fire({
      html: `<h2>Verifique os dados informados</h2>
      <p>${msg}</p>`,
      confirmButtonColor: "black",
      confirmButtonText: "Fechar",
      allowOutsideClick: true,
      showCloseButton: true,

      customClass: {},
    });
  };
  const handleCep = async (e: any) => {
    e.preventDefault();

    if (e.target.value.replace(/\D/g, "").length < 8) {
      setFormData((prevState: any) => ({
        ...prevState,
        address_street: "",
      }));
    }

    if (e.target.value.replace(/\D/g, "").length === 8) {
      try {
        const getCep = await axios.get(
          `https://brasilapi.com.br/api/cep/v1/${e.target.value.replace(
            /\D/g,
            ""
          )}`
        );
        setAddress(getCep.data);
        if (getCep.data.street.length > 0) {
          setFormData((prevState: any) => ({
            ...prevState,
            address_street: getCep.data.street,
          }));
        }
      } catch (e) {
        alertOpen("CEP não encontrado. Tente novamente.");
      }
    }
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      name: event.target.name.value,
      cpf: event.target.cpf.value,
      status_civil: event.target.status_civil.value,
      address_cep: event.target.address_cep.value,
      address_street: event.target.address_street.value,
      address_number: event.target.address_number.value,
      address_complement: event.target.address_complement.value,
      exposed: event.target.exposed.value,
      salary: event.target.salary.value,
      selected_product: lead.selected_product,
    };

    if (!Utils.changeEmptyValueToNull(data.name)) {
      alertOpen("Nome em branco.");
    } else if (data.name.length < 10) {
      alertOpen("Digite o nome completo.");
    } else if (!Utils.validateCpf(data.cpf)) {
      alertOpen("CPF inválido.");
    } else if (!Utils.changeEmptyValueToNull(data.status_civil)) {
      alertOpen("Estado civil não selecionado.");
    } else if (!Utils.IsCEP(data.address_cep)) {
      alertOpen("CEP inválido!");
    } else if (!Utils.changeEmptyValueToNull(data.address_street)) {
      alertOpen("Endereço não informado");
    } else if (!Utils.changeEmptyValueToNull(data.address_number)) {
      alertOpen("Número do endereço não informado.");
    } else if (data.salary === "0") {
      alertOpen("Informe sua média salarial.");
    } else if (data.exposed === "0") {
      alertOpen("Selecione uma opção para politicamente exposto.");
    } else if (lead.leadId) {
      await putLead({ leadId: lead.leadId, ...data, address });
      history.push("/pagamento");
    }
  };

  return (
    <Fragment>
      <GlobalStyle />

      <MyDataStyled>
        <form></form>
        <Container>
          <PackageNav
            lead={lead}
            color="#000"
            title="Falta pouco!"
            active="Dados"
          />
          <div className="box-section">
            <div className="box-card">
              <h2>Precisamos de mais alguns dados para prosseguir:</h2>
              <p>
                *Preencha certinho para que não tenha problemas para ativar seu
                seguro no futuro.
              </p>
              {/* <p>{JSON.stringify(lead)}</p> */}

              <FormStyled onSubmit={handleSubmit}>
                <InputRowStyled icon={iconName}>
                  <span className="userSpan" />
                  <input
                    type="text"
                    placeholder="Nome completo"
                    className="name"
                    name="name"
                    defaultValue={lead.name}
                  />
                </InputRowStyled>
                <InputRowStyled icon={iconCPF}>
                  <span />
                  <MaskedInput
                    mask={[
                      /\d/,
                      /\d/,
                      /\d/,
                      ".",
                      /\d/,
                      /\d/,
                      /\d/,
                      ".",
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                    ]}
                    //type="date"
                    type="text"
                    placeholder="CPF"
                    className="cpf"
                    name="cpf"
                    defaultValue={lead.cpf}
                  />
                </InputRowStyled>
                <fieldset>
                  <button type="button" className="check-genre bg_btn_chose_1">
                    <input
                      defaultChecked={lead.status_civil === "Solteiro"}
                      type="radio"
                      name="status_civil"
                      value="Solteiro"
                    />
                    Solteiro
                  </button>
                  <button type="button" className="check-genre bg_btn_chose_2">
                    <input
                      defaultChecked={lead.status_civil === "União Estavel"}
                      type="radio"
                      name="status_civil"
                      value="União Estavel"
                    />
                    União estável
                  </button>
                  <button type="button" className="check-genre bg_btn_chose_3">
                    <input
                      defaultChecked={lead.status_civil === "Casado"}
                      type="radio"
                      name="status_civil"
                      value="Casado"
                    />
                    Casado
                  </button>
                </fieldset>
                <InputRowStyled icon={iconCEP}>
                  <span />
                  <MaskedInput
                    mask={[
                      /\d/,
                      /\d/,
                      ".",
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    type="text"
                    placeholder="CEP"
                    className="cep"
                    name="address_cep"
                    defaultValue={lead.address_cep}
                    onChange={handleCep}
                  />
                </InputRowStyled>
                <InputRowStyled icon={iconAdress}>
                  <span />
                  <input
                    type="text"
                    placeholder="Endereço"
                    className="address"
                    name="address_street"
                    value={formData.address_street}
                    disabled
                  />
                </InputRowStyled>
                <InputRowStyled icon={iconLocation}>
                  <span />
                  <input
                    type="text"
                    placeholder="Número"
                    className="address_number"
                    name="address_number"
                    defaultValue={lead.address_number}
                  />{" "}
                </InputRowStyled>{" "}
                <InputRowStyled icon={iconAdressComp}>
                  <span />
                  <input
                    type="text"
                    placeholder="Complemento"
                    className="adress-comp"
                    name="address_complement"
                    defaultValue={lead.address_complement}
                  />
                </InputRowStyled>
                <select
                  name="salary"
                  className="salary"
                  defaultValue={lead.salary}
                >
                  <option key={0} value={0} disabled>
                    Média Salarial
                  </option>
                  <option key={1} value={1500}>
                    Até 1.500
                  </option>
                  <option key={2} value={3000}>
                    Entre 1.500,00 a 3.000,00
                  </option>
                  <option key={3} value={5000}>
                    Superior a 3.000,00
                  </option>
                </select>
                <select
                  name="exposed"
                  className="exposed"
                  defaultValue={lead.exposed}
                >
                  <option key={0} value={0} disabled>
                    Politicamente Exposto
                  </option>
                  <option key={1} value={"false"}>
                    Não
                  </option>
                  <option key={2} value={"true"}>
                    Sim
                  </option>
                </select>
                <input type="submit" value="Prosseguir" />
              </FormStyled>
            </div>
          </div>
        </Container>
      </MyDataStyled>

      <Container>
        <Sabemi />
      </Container>
    </Fragment>
  );
};

export default MyDataPage;
