import { Fragment, useEffect, useState } from "react";
import { ButtonPlan, PackageStyled, ButtonWhite } from "./package.styled";
import {
  ButtonTextBold,
  GlobalStyle,
  StyledModal,
  ModalContent,
} from "../../styles/global.styled";
import PackageNav from "../../Components/PackageNav/packageNav.component";
import { useHistory } from "react-router";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { PlanDetail } from "../../Components/PlanDetail";
import option1 from "../../images/option1.png";
import option2 from "../../images/option2.png";
import { useLead } from "../../hooks/lead";
import Sabemi from "../../Components/Sabemi";
import { updateEvent } from "../../services/api";
const MySwal = withReactContent(Swal);

export const alertOpen = (Component: any) => {
  MySwal.fire({
    html: Component,
    grow: "fullscreen",
    confirmButtonColor: "black",
    confirmButtonText: "Fechar",
    cancelButtonText: "Fechar",
    showCancelButton: true,
    showConfirmButton: false,
    allowOutsideClick: true,
    showCloseButton: false,
    showClass: {
      backdrop: "", // disable backdrop animation
      popup: "", // disable popup animation
      icon: "", // disable icon animation
    },
    hideClass: {
      popup: "",
    },
    customClass: {},
  });
};

const PackagePage = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const { lead, selectProduct } = useLead();

  const history = useHistory();

  useEffect(() => {
    // console.log(lead);
  }, []);

  const handleClick = async (selected_product: any) => {
    setIsOpen(false);
    selectProduct(selected_product);
    await updateEvent({ leadId: lead.leadId, type: "COMPRAR" });
    history.push("/dados");
  };

  const handleModal = async (selected_product: any) => {
    //MySwal.close();
    await updateEvent({ leadId: lead.leadId, type: "MAIS_DETALHES" });
    selectProduct(selected_product);
    setIsOpen(true);
  };

  return (
    <Fragment>
      <GlobalStyle />
      {/*  <p>{JSON.stringify(lead)}</p> */}
      <PackageStyled>
        <div className="container">
          <PackageNav
            lead={lead}
            color="#000"
            title="Escolha o seu plano"
            active="Planos"
          />
          <div className="priceTable">
            <div className="row">
              <div className="col">
                <img src={option1} alt="Torcedor Protegido" />
                <div className="price">
                  <span>R$</span> <strong>14</strong>
                  <span className="cents">
                    <i>,90</i>/mês
                  </span>
                </div>
                <ButtonPlan
                  value="Torcedor Protegido"
                  onClick={() => handleClick("Torcedor Protegido")}
                >
                  COMPRAR
                </ButtonPlan>

                <div className="plan">
                  <h2>Torcedor Protegido</h2>
                  <p>
                    R$ <strong>14,90</strong> /mês
                  </p>
                  <h3>Capital Segurado</h3>
                  <ul>
                    <li>
                      Morte Acidental
                      <span>
                        Indenização:
                        <strong>R$ 10 mil</strong>
                      </span>
                    </li>
                    <li>
                      Invalidez Permanente Total ou Parcial por Acidente (IPA)
                      <span>
                        Indenização até:
                        <strong>R$ 10 mil</strong>
                      </span>
                    </li>
                  </ul>

                  <h3>Serviços</h3>
                  <ul>
                    <li>Assistência Funeral Individual</li>
                    <li>Proteção Pessoal</li>
                    <li>Auto Restrito</li>
                  </ul>

                  <h3>Benefícios</h3>
                  <ul>
                    <li>
                      Sorteio Mensal: <br />
                      <strong>R$ 5 mil</strong> para o segurado. <br />
                      <strong>R$ 5 mil</strong> para o Sporting Club Ceará.
                    </li>
                  </ul>
                  <div style={{ marginTop: "50px" }}>
                    <ButtonTextBold
                      onClick={() => handleModal("Torcedor Protegido")}
                    >
                      + Mais detalhes
                    </ButtonTextBold>
                  </div>
                </div>
              </div>
              <div className="col">
                <img src={option2} alt="Torcedor Protegido Plus" />
                <div className="price">
                  <span>R$</span> <strong>24</strong>
                  <span className="cents">
                    <i>,90</i>/mês
                  </span>
                </div>
                <ButtonPlan
                  value="Torcedor Protegido Plus"
                  onClick={() => handleClick("Torcedor Protegido Plus")}
                >
                  COMPRAR
                </ButtonPlan>

                <div className="plan">
                  <h2>Torcedor Protegido Plus</h2>

                  <p>
                    R$ <strong>24,90</strong> /mês
                  </p>
                  <h3>Capital Segurado</h3>
                  <ul>
                    <li>
                      Morte Acidental
                      <span>
                        Indenização:
                        <strong>R$ 20 mil</strong>
                      </span>
                    </li>
                    <li>
                      Invalidez Permanente Total ou Parcial por Acidente (IPA)
                      <span>
                        Indenização até:
                        <strong>R$ 20 mil</strong>
                      </span>
                    </li>
                  </ul>

                  <h3>Serviços</h3>
                  <ul>
                    <li>Assistência Funeral Individual</li>
                    <li>Proteção Pessoal</li>
                    <li>Auto Restrito</li>
                    <li>Residencial Eletroassist</li>
                  </ul>

                  <h3>Benefícios</h3>
                  <ul>
                    <li>
                      Sorteio Mensal: <br />
                      <strong>R$ 10 mil</strong> para o segurado <br />
                      <strong>R$ 10 mil</strong> para o Sporting Club Ceará
                    </li>
                  </ul>
                  <div style={{ marginTop: "50px" }}>
                    <ButtonTextBold
                      onClick={() => handleModal("Torcedor Protegido Plus")}
                    >
                      + Mais detalhes
                    </ButtonTextBold>
                  </div>
                </div>
              </div>
            </div>
            {/* {showModal && (
              <Modal
                lead={props.location.state.lead}
                selected_product={product}
                setShowModal={setShowModal}
              />
            )} */}

            <div className="info-plans">
              <p>
                A assistência funeral compreende somente a prestação do serviço
                de funeral, não estando disponível o reembolso das despesas.
              </p>
              <p>
                Este seguro é garantido pela Sabemi Seguradora S/A, CNPJ
                87.163.234/0001-38. Registro Susep Seguro AP nº 001-02162/94. A
                aceitação do seguro estará sujeita à análise do risco. O
                registro deste plano na SUSEP não implica, por parte da
                Autarquia, incentivo ou recomendação a sua comercialização.
                Sorteios em dinheiro lastreados por Título de Capitalização, da
                modalidade incentivo, emitidos pela Invest Capitalização CNPJ
                93.202.448/0001-79 e aprovado pela SUSEP sob o Registro SUSEP
                15414.900581/2019-13.
              </p>
            </div>
          </div>
          <Sabemi />
        </div>
      </PackageStyled>
      <StyledModal isOpen={isOpen}>
        <ModalContent>
          <PlanDetail
            selected_product={lead.selected_product}
            handleClick={handleClick}
          />
          <ButtonWhite onClick={() => setIsOpen(false)}>Fechar</ButtonWhite>
        </ModalContent>
      </StyledModal>
    </Fragment>
  );
};

export default PackagePage;
