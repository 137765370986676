import React, { useEffect, useState } from "react";
import { getReport } from "../../services/api";
import { GlobalStyle } from "../../styles/global.styled";

import { Container } from "./styles";

const Reports: React.FC = () => {
  const [authorize, setAuthorize] = useState(false);
  useEffect(() => {}, []);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      e.target.login.value === "sabemi" &&
      e.target.password.value === "4789"
    ) {
      setAuthorize(true);
    }
    console.log(e.target.password.value);
  };
  const handleClick = async (e: any) => {
    const blobFile = await getReport(e.target.value);
    const url = window.URL.createObjectURL(new Blob([blobFile]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `leads.${e.target.value}`);
    document.body.appendChild(link);
    link.click();
  };
  return (
    <Container>
      <GlobalStyle></GlobalStyle>
      {!authorize && (
        <div className="modal">
          <h2>Acesso Restrito:</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Login:
              <input type="text" name="login" />
            </label>
            <label>
              Senha:
              <input type="password" name="password"></input>
            </label>
            <button type="submit">Entrar</button>
          </form>
        </div>
      )}

      {authorize && (
        <div>
          <h2>Relatório de Leads</h2>
          <p>Escolha uma opção para download.</p>
          <button value={"xlsx"} onClick={handleClick}>
            Excel
          </button>

          <button value={"csv"} onClick={handleClick}>
            CSV
          </button>
        </div>
      )}
    </Container>
  );
};

export default Reports;
