import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  background: #000;
  height: 100vh;

  h2 {
    font-size: 24px;
  }
  button {
    display: block;
    margin: 20px auto;
    width: 200px;
    height: 40px;
    background: #fff;
    font-size: 18px;
    border: #000 solid 2px;
    font-weight: bold;
  }
  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 5px;
    text-align: left;
  }
  input {
    height: 35px;
    padding-left: 12px;
    padding-bottom: 5px;
    font-size: 18px;
    width: 200px;
  }
  div {
    padding-top: 80px;
    border-radius: 12px;
    margin: 10px auto;
    width: 300px;
    height: 400px;
    background: #fff;
    box-shadow: 2px 2px 2px 1px rgba(255, 255, 255, 0.6);
  }
`;
