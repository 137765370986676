import styled from "styled-components";

export const PackageNavStyled = styled.div`
  .stepsPackage ul {
    display: flex;
    justify-content: space-between;

    li {
      width: 100%;
      text-align: center;
      padding: 0 1px;
      button {
        border: none;
        font-size: 13px;
        display: block;
        color: ${({ color }: { color: string }) => color};
        //color:rgb(0 0 0 / 25%);
        border-top: 9px solid rgb(0 0 0 / 25%);
        padding-top: 4px;
        margin: 0 auto;
        width: 100%;

        color: inherit;
        outline: inherit;
        background: none;

        @media (max-width: 600px) {
          font-size: 9px;
        }
      }
      button[disabled] {
        cursor: default;
      }
      a {
        font-size: 13px;
        display: block;
        color: ${({ color }: { color: string }) => color};
        //color:rgb(0 0 0 / 25%);
        border-top: 9px solid rgb(0 0 0 / 25%);
        padding-top: 4px;

        @media (max-width: 600px) {
          font-size: 9px;
        }
      }
    }

    .active {
      a {
        color: ${({ color }: { color: string }) => color};
        font-weight: bold;
        border-color: #000;
      }
      button {
        color: ${({ color }: { color: string }) => color};
        font-weight: bold;
        border-color: #000;
      }
    }
  }

  .introNav {
    position: relative;
    margin: 56px 0;

    @media (min-width: 600px) and (max-width: 1366px) {
      margin: 56px 30px;
    }

    @media (max-width: 600px) {
      margin: 20px 0 0;
      padding: 0 40px;
    }
    h1 {
      color: ${({ color }: { color: string }) => color};
      text-align: center;
      font-size: 30px;
      padding: 0 100px;

      @media (max-width: 600px) {
        color: #fff;
        font-size: 24px;
        padding: 0 15px;
        line-height: 32px;
      }
    }
    span {
      color: ${({ color }: { color: string }) => color};
      position: absolute;
      top: 10px;
      font-weight: bold;

      @media (max-width: 600px) {
        position: relative;
        margin-bottom: 35px;
        display: block;
        color: transparent;
      }

      img {
        margin-right: 5px;
      }
    }
  }
`;
