import { format, parse } from "date-fns";
import React, { useState, useCallback, useContext, createContext } from "react";
import { createLead, updateEvent, updateLead } from "../services/api";
import getLocation from "../services/getIp";

const LeadContext = createContext({} as any);
const initialState = {
  birthday: "",
  cellphone: "",
  email: "",
  leadId: "",
  profession: "profession",
  sex: "",
  selected_product: "",
  name: "",
  cpf: "",
  status_civil: "",
  address_cep: "",
  address_street: "",
  address_number: "",
  address_complement: "",
  exposed: 0,
  salary: 0,
  check_roles: false,
  check_bb: false,
  payment_option: "0",
  bank_number: "",
  bank_account: "",
  bank_agency: "",
  eventAccessId: "",
};

export const LeadProvider: React.FC = ({ children }) => {
  const [lead, setLead] = useState(initialState);

  const postLead = useCallback(
    async (data) => {
      const birthday = format(
        parse(data.birthday, "dd/MM/yyyy", new Date()),
        "yyyy-MM-dd"
      );

      const cellphone = data.cellphone.replace(/\D/g, "");

      const { leadId } = await createLead({
        ...data,
        birthday,
        cellphone,
        eventAccessId: lead.eventAccessId,
      });
      setLead((prevState) => ({ ...prevState, ...data, leadId }));
    },
    [lead]
  );

  const putLead = useCallback(async (data) => {
    const cpf = data.cpf.replace(/\D/g, "");
    const address_cep = data.address_cep.replace(/\D/g, "");

    await updateLead(data.leadId, { ...data, address_cep, cpf });
    setLead((prevState) => ({ ...prevState, ...data }));
  }, []);

  const selectProduct = useCallback((option) => {
    setLead((prevState) => ({ ...prevState, selected_product: option }));
  }, []);

  const handleLead = useCallback((option) => {
    setLead((prevState) => ({ ...prevState, ...option }));
  }, []);

  const handleLocation = useCallback(async (queryParams) => {
    const location = await getLocation();
    const eventAccessId = await updateEvent({
      type: "ACESSO",
      params: queryParams,
      location: location,
    });

    setLead((prevState) => ({ ...prevState, eventAccessId }));
  }, []);

  const handleEventLead = useCallback((e) => {
    let value = e.target.value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    setLead((prevState: any) => ({
      ...prevState,
      [e.target.name]: value,
    }));
  }, []);

  const stateClean = useCallback(() => {
    setLead(initialState);
  }, []);

  return (
    <LeadContext.Provider
      value={{
        lead,
        postLead,
        selectProduct,
        putLead,
        stateClean,
        updateLead,
        handleEventLead,
        handleLead,
        handleLocation,
      }}
    >
      {children}
    </LeadContext.Provider>
  );
};

export function useLead() {
  const context = useContext(LeadContext);

  if (!context) throw new Error("useLead must be used within an LeadProvider");

  return context;
}
