import React from "react";

import { Container } from "./styles";

const Privacy: React.FC = () => {
  return (
    <Container>
      <h3>Política de Privacidade</h3>

      <p>
        Para a Sabemi, privacidade, segurança e transparência são valores
        fundamentais e sempre adotaremos as melhores práticas para garantir a
        confidencialidade, idoneidade, segurança e integridade dos seus dados
        pessoais pois nos colocamos na perspectiva não só dos nossos clientes,
        mas também de quem acessa nossas plataformas digitais.
      </p>
      <p>
        Neste sentido, a presente Política de Privacidade determina as
        diretrizes e tem como objetivo principal informar de maneira clara,
        simples e objetiva, como os seus dados pessoais são tratados
        considerando o disposto na Lei Geral de Proteção de Dados - Lei nº
        13.709/2018 – (“LGPD”) reafirmando nosso compromisso de garantir que
        você, como titular estará no controle de seus dados em conformidade com
        os princípios de privacidade e proteção de dados pessoais.
      </p>
      <p>
        Ao acessar nossas plataformas digitais (site, portal do cliente,
        aplicativos, aceite web, etc.) e concordar com os termos dessa Política
        de Privacidade, você está ciente de que as empresas Sabemi Seguradora
        S/A, Sabemi Previdência, Sabemi Negócios, Sabemi TEC ou qualquer outra
        empresa parceira são as controladoras, no que couber, sobre decisões do
        tratamento dos seus dados pessoais.
      </p>
      <p>
        Disponível em:{" "}
        <a href="https://www.sabemi.com.br/politica-de-privacidade">
          https://www.sabemi.com.br/politica-de-privacidade/
        </a>{" "}
      </p>
    </Container>
  );
};

export default Privacy;
