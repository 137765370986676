import React, { Fragment } from "react";
import { OrderSummaryStyled } from "./styles";
import { useHistory } from "react-router-dom";
import { getUrlPagamento } from "../../services/api";
import Swal from "sweetalert2";
import { useLead } from "../../hooks/lead";
import BankAccountValidator from "br-bank-account-validator";

type CheckoutProps = {
  leadId: string;
  selected_product: string;
  formData: any;
};

export const Checkout = ({
  leadId,
  selected_product,
  formData,
}: CheckoutProps) => {
  const { lead, stateClean } = useLead();
  const history = useHistory();
  const handleClick = async (e: any) => {
    e.preventDefault();

    if (lead.payment_option === "1") {
      let bank_number = lead.bank_number;
      let bank_agency_check = "";
      let bank_agency = lead.bank_agency.replace(/\D/g, "").slice(0, 4);

      if (lead.bank_agency.length > 4) {
        bank_agency_check = lead.bank_agency.slice(-1);
      }
      let bank_account = lead.bank_account.replace(/\D/g, "");
      let bank_account_check = lead.bank_account.slice(-1);

      if (lead.bank_number === "001" || lead.bank_number === "033") {
        if (bank_account.length < 9) {
          const diff = 9 - bank_account.length;
          bank_account = `${"0".repeat(diff)}${bank_account}`.slice(0, 8);
        }
        bank_account = bank_account.slice(0, 8);
      }

      if (lead.bank_number === "237") {
        if (bank_account.length < 8) {
          const diff = 8 - bank_account.length;
          bank_account = `${"0".repeat(diff)}${bank_account}`.slice(0, 7);
        } else {
          bank_account = bank_account.slice(0, 7);
        }
      }

      if (lead.bank_number === "341") {
        if (bank_account.length < 6) {
          const diff = 6 - bank_account.length;
          bank_account = `${"0".repeat(diff)}${bank_account}`.slice(0, 5);
        } else {
          bank_account = bank_account.slice(0, 5);
        }
      }

      try {
        const agencyNumber = bank_agency;
        const agencyCheckNumber = bank_agency_check;
        const accountNumber = bank_account;
        const accountCheckNumber = bank_account_check;

        BankAccountValidator.validate({
          bankNumber: bank_number,
          agencyNumber,
          agencyCheckNumber,
          accountNumber,
          accountCheckNumber,
        });
      } catch (e) {
        Swal.fire({
          html: `<h4>Dados bancários inválidos</h4>
          <p>Verifique a agência e conta informada.</p>`,
          confirmButtonColor: "black",
          confirmButtonText: "Fechar",
        });
        console.log(e);
        return false;
      }
    }

    if (lead.payment_option === "0") {
      Swal.fire({
        html: `<h4>Selecione uma opção para pagamento.</h4>`,
        confirmButtonColor: "black",
        confirmButtonText: "Fechar",
      });
    } else if (
      lead.payment_option === "1" &&
      (lead.bank_agency.length > 6 ||
        lead.bank_agency.replace(/\D/g, "").length < 4)
    ) {
      Swal.fire({
        html: `<h4>Dados bancários inválidos</h4>`,
        confirmButtonColor: "black",
        confirmButtonText: "Fechar",
      });
    } else if (
      lead.payment_option === "1" &&
      (lead.bank_account.length > 13 ||
        lead.bank_account.replace(/\D/g, "").length < 6)
    ) {
      Swal.fire({
        html: `<h4>Dados bancários inválidos</h4>`,
        confirmButtonColor: "black",
        confirmButtonText: "Fechar",
      });
    } else if (!lead.check_roles) {
      Swal.fire({
        html: `<h4>Pendente confirmação.</h4>"Li e 
               concordo com as regras deste seguro..."`,
        confirmButtonColor: "black",
        confirmButtonText: "Fechar",
      });
    } else if (
      lead.payment_option === "1" &&
      lead.bank_number === "001" &&
      !lead.check_bb
    ) {
      Swal.fire({
        html: `<h4>Pendente confirmação.</h4><p>Confirme 
        os dados e autorização para cobrança do Seguro Sabemi.</p>`,
        confirmButtonColor: "black",
        confirmButtonText: "Fechar",
      });
    } else {
      if (
        (lead.payment_option === "1" && lead.bank_number === "001") ||
        lead.payment_option === "3"
      )
        await Swal.fire({
          html: `<p>Você será redirecionado para o ambiente de pagamento.</p>`,
          showConfirmButton: false,
          timer: 4000,
        });
      const data = await getUrlPagamento({
        leadId,
        payment_option: lead.payment_option,
        selected_product,
        bank_account: lead.bank_account
          ? lead.bank_account.replace(/\D/g, "")
          : "",
        bank_agency: lead.bank_agency
          ? lead.bank_agency.replace(/\D/g, "")
          : "",
        bank_number: lead.bank_number || "",
      });

      //console.log(data);
      if (data.sucesso && data.url === "") {
        //console.log("pendente");
        stateClean();
        history.push("/pendente");
      } else if (!data.sucesso) {
        //console.log("falha");
        stateClean();
        history.push("/falha");
      } else {
        //console.log("redirect");
        window.location.replace(data.url);
      }
    }
  };

  return (
    <Fragment>
      <OrderSummaryStyled>
        <ul>
          <li>
            2 <span>Coberturas</span>
          </li>
          {lead.selected_product === "Torcedor Protegido" && (
            <li>
              3 <span>Assistências</span>
            </li>
          )}
          {lead.selected_product === "Torcedor Protegido Plus" && (
            <li>
              4 <span>Assistências</span>
            </li>
          )}
        </ul>
        <div className="final-cost">
          {lead.selected_product === "Torcedor Protegido" && (
            <p>
              <strong>R$</strong>14,90<small>/mês</small>
            </p>
          )}
          {lead.selected_product === "Torcedor Protegido Plus" && (
            <p>
              <strong>R$</strong>24,90<small>/mês</small>
            </p>
          )}
          <span>Valor final</span>
        </div>
        <div className="btn-next">
          <button onClick={handleClick}>Contratar</button>
        </div>
      </OrderSummaryStyled>
    </Fragment>
  );
};
