import styled from "styled-components";
import iconCheck from "../../images/check-list.svg";
import smoke from "../../images/smoke.png";

export const Container = styled.div`
  display: flex;
`;

export const ButtonWhite = styled.button`
  border: 0;

  background: #fff;
  width: 160px;
  margin: 20px auto;
  display: block;
  padding: 10px;
  border: 2px solid #000;
  border-radius: 12px;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;
export const ButtonPlan = styled.button`
  border: 0;
  z-index: 9;
  background: none;
  width: 160px;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
  padding: 10px;
  border: 2px solid #f8f8ff;
  border-radius: 12px;
  color: #f8f8ff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: #f8f8ff;
    color: #000000;
  }
`;
export const PackageStyled = styled.div`
  //background: #fff;
  .info-plans {
    color: #fff;
    margin: 10px auto;
    padding: 10px;
  }
  .priceTable {
    position: relative;
    background: #000;
    padding: 200px;
    padding-bottom: 20px;

    @media (min-width: 600px) {
      &:after {
        content: "";
        z-index: 1;
        background: url(${smoke}) no-repeat 0 4px;
        position: absolute;
        background-size: contain;
        background-position: center;
        width: 100%;
        height: 691px;
        top: -180px;
        left: 0;
        pointer-events: none;
      }
    }

    @media (max-width: 600px) {
      padding: 36px 0;
    }
    .row {
      justify-content: space-around;
      align-items: flex-start;
      padding-bottom: 50px;
      justify-content: center;
      @media (max-width: 600px) {
        .col {
          position: relative;
          &:after {
            content: "";
            z-index: 1;
            background: url(${smoke}) no-repeat 0 4px;
            position: absolute;
            background-size: contain;
            background-position: center;
            width: 100%;
            height: 691px;
            top: -280px;
            left: 0;
            pointer-events: none;
          }
        }
      }
      img {
        max-width: 180px;
        display: block;
        margin: 10px auto;
        margin-right: auto;
        //border: 2px solid #fff;
        padding: 10px;
        border-radius: 12px;
        position: relative;
        z-index: 2;
      }
      @media (max-width: 600px) {
        display: block;
      }
    }

    .price {
      color: #f8f8ff;
      display: flex;
      align-items: flex-end;
      margin-bottom: 28px;
      justify-content: center;

      strong {
        font-size: 90px;
        font-weight: 800;
        line-height: 77px;
      }

      span {
        display: block;
      }

      .cents {
        i {
          font-size: 25px;
          font-weight: bold;
          display: block;
        }
      }
    }
    /* button {
    
    } */

    .plan {
      background: #ffffff;
      border-radius: 10px;
      padding: 29px;
      width: 300px;
      margin: 30px;

      @media (max-width: 600px) {
        width: auto;
      }

      a {
        color: #000000;
        font-size: 14px;
        text-decoration: underline;
        margin-top: 50px;
        display: block;
      }

      h2 {
        margin: 0;
        color: #000000;
        font-size: 18px;
        border-bottom: 2px solid #ccc;
        padding-bottom: 10px;
      }

      h3 {
        font-size: 16px;
        color: #000000;
      }

      p {
        color: #656565;
      }

      ul {
        li {
          color: #656565;
          background: url(${iconCheck}) no-repeat 0 4px;
          padding-left: 20px;
          span {
            color: #000000;
            display: block;

            strong {
              display: block;
            }
          }
        }
      }
    }
  }
`;
