import styled from "styled-components";

export const PlansStyled = styled.div`
  h1 {
    font-weight: normal;
  }

  h3 {
    text-align: center;
    font-size: 30px;
    color: #000000;
    font-weight: normal;
    max-width: 492px;
    margin: 0 auto;
    line-height: 40px;
  }

  .row {
    display: flex;
  }
  .row .col {
    flex: 1;
  }
  .row .col:last-child {
    margin-left: 1em;
  }

  /* Accordion styles */
  .tabs {
    border-radius: 8px;
    overflow: hidden;
  }

  label p {
    font-size: 24px;

    span {
      font-size: 18px;
      color: #888888;
      font-weight: normal;
      padding-left: 8px;
    }
  }

  label i {
    margin-right: 20px;
  }

  .tab {
    width: 100%;
    color: white;
    overflow: hidden;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 21px;
      top: 87px;
      height: 72%;
      z-index: 1;
      width: 2px;
      background: #eaeaea;
    }
  }
  .tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em 46px;
    background: #2c3e50;
    font-weight: bold;
    cursor: pointer;
    background: #f6f6f6;
    color: #000000;
    height: 95px;
    border-radius: 12px;
    align-items: center;
    z-index: 2;
    position: relative;
  }
  .tab-label:hover {
    background: #eee;
  }
  .tab-label::after {
    content: "❯";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
  }
  .tab-content {
    max-height: 0;
    padding: 0 1em;
    color: #2c3e50;
    background: white;
    transition: all 0.35s;
  }
  .tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
  }
  .tab-close:hover {
    background: #ccc;
  }

  input[type="radio"] {
    visibility: hidden;
  }

  input:checked + .tab-label {
    background: #f6f6f6;
    color: #000000;
    height: 95px;
    border-radius: 12px;
  }
  input:checked + .tab-label::after {
    transform: rotate(90deg);
  }
  input:checked ~ .tab-content {
    max-height: 100vh;
    padding: 20px 65px;
  }

  fieldset {
    height: 95px;
    border: 2px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 46px 0 0;
    margin-bottom: 19px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: -46px;
      width: 44px;
      height: 2px;
      background: #eaeaea;
    }

    label {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #000000;
      font-weight: bold;
      height: 100%;
      width: 100%;
      cursor: pointer;
      padding-left: 46px;

      img {
        margin-right: 10px;
      }
    }
  }
`;
