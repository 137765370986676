import styled from "styled-components";
import desktop from "../../images/dupla.png";

export const ContainerPaymentApproved = styled.div`
  color: #fff;

  height: 100vh;
  margin: 0 auto;

  background: url(${desktop});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  //display: flex;
  //flex-direction: column;

  //align-content: stretch;
  //align-items: stretch;
  //jutify-content: center;

  header {
  }
  main {
    background: rgb(0 0 0 /60%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 100px;
    height: 100%;
  }
  footer {
  }
  a {
    text-align: center;
    color: #fff;
    font-weight: normal;
    max-width: auto;
    margin: 0 auto;
    padding: 10px;
    margin: 0 auto;
    text-decoration: none;
    color: inherit;
    padding: 10px;
  }
  .qrcode {
    margin: 0 auto;
    padding: 10px;

    background-color: white;
  }
  p {
    text-align: center;
    color: #fff;
    font-weight: normal;
    max-width: auto;
    margin: 0 auto;
    padding: 10px;
    padding-bottom: 5px;
  }
  h2 {
    text-align: center;
    font-size: 30px;
    color: #fff;
    font-weight: normal;
    max-width: auto;
    margin: 0 auto;
    line-height: 40px;
    padding: 10px;

    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 25px;
      padding: 0 15px;
    }
  }
`;
