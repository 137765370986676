import React from "react";
import { Container, TextJustify } from "./styles";
const roles = `Pelo presente AUTORIZO a inclusão do meu nome na apólice de Seguro de Acidentes
Pessoais Coletivo contratado pelo Estipulante Clube de Seguros Pampa, 
CNPJ 74.875.972/0001-66, quem concedo o direito de agir
em meu nome no cumprimento ou na alteração de cláusulas das condições gerais e
especiais da apólice de apólice, devendo todas como comunicações ou avisos
inerentes ao contrato, que são encaminhados diretamente ao aludido Estipulante, que
para tal fim fica investido dos poderes de representação ou outorgados. Entretanto,
fica ressalvado que os poderes de representação ou outorgados não conferem ao
estipulante o direito de cancelar o seguro aqui proposto no decorrer de sua vigência
enquanto o pagamento do prêmio estiver sob minha responsabilidade, habilitado,
contudo, de que a apólice pode deixar de ser renovada em seu aniversário por decisão
do Estipulante ou da Seguradora, sem devolução dos prêmios pagos. Estou ciente de
que para solicitar o cancelamento deste seguro devo entrar em contrato com a
SABEMI, através dos meios de comunicação oferecidos por ela, e que um pedido de
cancelamento será acato quando for feito por mim ou por meu representante legal. A
seguradora terá o prazo de 15 (quinze) dias a contar da recepção da proposta para a
análise do risco, ficando convencionado que o início da vigência da cobertura se
exponha às 24 (vinte e quatro) horas do dia anterior ao pagamento do primeiro prêmio
ou, no caso de desconto em folha, às 24 (vinte e quatro) horas do último dia do mês
de competência do contracheque do Segurado em que ocorrer o desconto do primeiro
prêmio de Seguro. A ausência de pagamento do prêmio do seguro na data de seu
vencimento implicará na suspensão automática das garantias contratadas. Caso o
valor do prêmio pago, seja inferior ao contratado e / ou atualizado, o valor da garantia
será proporcionalmente. Estou ciente de que a atualização monetária dos prêmios e
dos capitais segurados será anual, no mesmo mês, dos anos seguintes, àquele
convencionado como sendo o mês de início de vigência do seguro, através do IGPM /
FGV ou, em substituição, pelo IPCA / IBGE. As informações contidas nesta proposta
são certas, completas e verdadeiras. Estou ciente de que quaisquer omissões ou
inexatidões contidas nas informações prestadas nesta proposta ocasionarão a perda
do direito ou uma resolução do contrato pela Seguradora, de acordo com os termos do
art. 766 do Código Civil, podendo ainda, responder civil e criminalmente pelas
informações inverídicas. DECLARO que li todo o conteúdo do presente contrato e que
ACEITO como cláusulas requer e que tomei conhecimento da integração das
Condições Gerais e Contratuais.`;

const RolesText: React.FC = () => {
  return (
    <Container>
      <TextJustify>{roles}</TextJustify>
    </Container>
  );
};

export default RolesText;
