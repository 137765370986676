import styled from "styled-components";

const InputLabel = styled.label``;
const InputSelect = styled.div`
  margin: auto;

  select {
    white-space: wrap;
    text-overflow: ellipsis;
    option {
      color: black;
      font-size: 1rem;
      border-bottom: 1px solid #000;
      text-overflow: ellipsis;
    }

    option[value=""][disabled] {
      display: none;
    }
  }

  select:required:invalid {
    color: gray;
  }
`;
const RegisterStyle = styled.div`
  max-width: 300px;
  background: ${(props: { color?: string }) => props.color};
  footer {
    width: 100%;
  }
`;

const InputRowStyled = styled.div`
  position: relative;
  > label {
    background: url(${(props: { icon?: string }) => props.icon}) no-repeat;
    position: absolute;
    top: 3px;
    text-indent: -9999px;
    width: 25px;
    height: 24px;
  }
`;

const InputStyled = styled.input`
  cursor: pointer;
  background: none;
  box-shadow: none;
  border: 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 20px;
  border-bottom: 2px solid #fff;
  padding-bottom: 13px;
  margin-bottom: 40px;
  color: #fff;
  padding-left: 40px;
`;

export { InputSelect, RegisterStyle, InputRowStyled, InputLabel, InputStyled };
