import { Fragment } from "react";
import { PackageNavStyled } from "../../Components/PackageNav/packageNav.styled";
import { useHistory } from "react-router-dom";
import iconBack from "../../images/back-arrow.svg";
import { ButtonTextBold } from "../../styles/global.styled";
type PackageNavProps = {
  color: string;
  title: string;
  active: string;
  lead: any;
};

const PackageNav = ({ lead, color, title, active }: PackageNavProps) => {
  const history = useHistory();
  const handleClick = (e: any) => {
    e.preventDefault();
    const linkFrom = e.target.name;

    if (linkFrom === "pagamento") {
    }

    history.push(`/${linkFrom}`);
  };
  const handleBack = () => {
    if (active === "Planos") {
      history.push("/");
    }
    if (active === "Dados") {
      history.push("/planos");
    }
    if (active === "Pagamento") {
      history.push("/dados");
    }
  };
  return (
    <Fragment>
      <PackageNavStyled color={color} title={title}>
        <nav className="stepsPackage">
          <ul>
            <li className={active === "Planos" ? "active" : ""}>
              <button
                disabled={active === "Planos"}
                name="planos"
                onClick={handleClick}
              >
                Planos
              </button>
            </li>
            <li className={active === "Dados" ? "active" : ""}>
              <button
                disabled={active === "Dados" || active === "Planos"}
                name="dados"
                onClick={handleClick}
              >
                Meus dados
              </button>
            </li>

            <li className={active === "Pagamento" ? "active" : ""}>
              <button disabled name="pagamento" onClick={handleClick}>
                Pagamento
              </button>
            </li>
          </ul>
        </nav>

        <div className="introNav">
          <span>
            <img src={iconBack} alt="Voltar" />
            <ButtonTextBold onClick={handleBack}>Voltar</ButtonTextBold>
          </span>

          <h1>{title}</h1>
        </div>
      </PackageNavStyled>
    </Fragment>
  );
};

export default PackageNav;
