import { GlobalStyle } from "../../styles/global.styled";
import { ContainerPaymentApproved } from "./styles";
import alertCircle from "../../images/alertcirclewhite.svg";
import Sabemi from "../../Components/Sabemi";

const PaymentFail = () => {
  return (
    <>
      <GlobalStyle />
      <ContainerPaymentApproved>
        <main>
          <img src={alertCircle} alt="Falha no pagamento." />
          <h1>Ops! Nosso sistema se comportou de forma inesperada :(</h1>
          <h2>Mas fique tranquilo! </h2>
          <h2>
            Em breve entraremos em contato e será possível dar andamento à
            contratação do seu seguro.
          </h2>

          <h2>Fique atento no seu celular, combinado?</h2>
        </main>
        <footer>
          <Sabemi />
        </footer>
      </ContainerPaymentApproved>
    </>
  );
};

export default PaymentFail;
