import styled, { createGlobalStyle } from "styled-components";
import Modal from "styled-react-modal";

export const StyledModal = Modal.styled`
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
   
    width: 100vw;
    height: 100%;
    //background: #fff;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    
`;
export const ModalContent = styled.div`
  background: #fff;

  padding: 10px;
  max-width: calc(100vw - 1rem);
  max-height: calc(100vh - 1rem);
  border: 3px solid #000;
  width: 960px;
  overflow-y: auto;

  position: relative;
  font-family: Calibri, "Open Sans", sans-serif, "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (max-width: 960px) {
    width: calc(100vw - 1rem);
  }
`;

export const ModalButton = styled.button`
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
`;

export const ButtonPrimary = styled.button`
  background: #000;
  color: #fff;
  width: 160px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #000;
  display: block;

  &:hover {
    background: #fff;
    color: #000;
    border: 2px solid #000;
  }
`;

export const GlobalStyle = createGlobalStyle`
 
  
  * {
    box-sizing:border-box;
    
    
  }
  body {
   
    margin: 0 auto;
    font-family: Calibri,'Open Sans', sans-serif, 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

    a {
        text-decoration:none;
    }

    ul {
        margin:0;
        list-style: none;
        padding:0;
    }

    .container {
        width: 100%;
       max-width: 1040px;
        margin:0 auto;
    }

    .row {
        display: flex;
    }

    input:focus {
        outline: none;
    }
 
   
    button {
        cursor: pointer;
    }

    footer {
        .logo {
          @media (max-width: 600px) {
          text-align: center;
        }
      }
      
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
    transition-delay: 9999s;

    
    
  }
`;

export const Container = styled.div`
  max-width: 1040px;
  margin: 0 auto;
  width: 100%;
`;

export const SectionStyled = styled.div`
  background: ${({ background }: { background: string }) => background};
`;

export const ButtonText = styled.button`
  border: none;
  background-color: inherit;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
`;

export const ButtonTextBold = styled.button`
  border: none;
  background-color: inherit;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
`;

export const ButtonBlack = styled.button`
  padding: 10px;
  margin: "auto";
  border: 2px solid #fff;
  border-radius: "12px";
  background: #000;
  color: #fff;
  font-weight: "bold";
  font-size: "18px";
  transition: ease 0.2s;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: #000;
    border: 2px solid #000;
  }
`;
