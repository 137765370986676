import React from "react";

import { Container } from "./styles";

const DataUse: React.FC = () => {
  return (
    <Container>
      <h3>Como utilizamos os seus dados pessoais?</h3>A Sabemi utiliza seus
      dados pessoais para algumas finalidades, tais como:
      <ul>
        <li>
          <strong>Operações comerciais:</strong> Comercializar produtos;
          responder solicitações; acompanhar solicitações de pedidos;
          publicidade de eventos, oferta de produtos e serviços; entre outros.
        </li>
        <li>
          <strong>Obrigações legais:</strong> Gerenciar eventos adversos;
          realizar atividades de prevenção e investigação; cumprir formalidades
          administrativas; fazer registros, declarações ou auditorias; entre
          outros.
        </li>
        <li>
          <strong>Acesso a serviços:</strong> Dar acesso a aplicativos e
          plataformas virtuais, gerenciar suas contas online, entre outros.
        </li>
        <li>
          <strong>Identificação: </strong>Identificar credenciais de acesso,
          incluindo senhas, dicas de senha, informações e perguntas sobre
          segurança, número do profissional de saúde, dados da carteira de
          motorista ou do passaporte, entre outros.
        </li>
        <li>
          <strong> Melhores produtos: </strong>Identificar tendências de uso;
          desenvolver produtos e serviços; entender como você e seu dispositivo
          interagem com nossos serviços; rastrear e responder riscos de
          segurança; determinar a eficácia de nossas campanhas promocionais;
          realizar pesquisas; entre outros.
        </li>
        <li>
          <strong> Experiência única:</strong> Garantir a melhor apresentação
          dos nossos serviços e produtos; entender seus interesses profissionais
          e pessoais; nos adaptar às suas necessidades e preferências; oferecer
          soluções sob medida; entre outros.
        </li>
        <li>
          <strong> Pagamentos:</strong> Verificar seus dados financeiros,
          facilitar pagamentos adicionais, entre outros.
        </li>
        <li>
          <strong> Comunicação:</strong> Responder pedidos ou perguntas; dar
          suporte para produtos e serviços; oferecer informações
          administrativas, avisos e materiais promocionais; enviar notícias e
          informações sobre nossos produtos, serviços, marcas e operações;
          organizar e gerenciar eventos e congressos profissionais, incluindo
          sua participação neles; entre outros.
        </li>
        <li>
          <strong> Direitos:</strong> Zelar pela saúde e segurança dos
          funcionários e instalações da Sabemi; fazer auditorias internas e
          gerenciamento de ativos, sistema e outros controles de negócios;
          gerenciar a administração de negócios; manter a segurança dos serviços
          e operações; proteger nossos direitos, privacidade, segurança ou
          propriedade, a fim de buscar soluções ou limitar danos; nos proteger
          contra possíveis ações fraudulentas; entre outros.
        </li>
        <li> Contratação de produtos de seguros;</li>
        <li>
          Contratação de plano de previdência, com cobertura de pecúlio por
          morte;
        </li>
        <li>Contração de assistência financeira;</li>
        <li>Análise de crédito; </li>
        <li>Análise de crédito;</li>
        <li>
          Inclusão de descontos em folha de pagamento e/ou débito em conta
          corrente e/ou cartão de crédito, referentes aos pagamentos dos prêmios
          de seguro, contribuição previdenciária e parcelas do contrato de
          assistência financeira;
        </li>
        <li>
          Depósito dos valores decorrentes do contrato de assistência financeira
          contratada;
        </li>
        <li>Regulação de processos de sinistros;</li>
        <li>Fornecer atendimento ao cliente;</li>
        <li>
          Prestar informações à Superintendência de Seguros Privados –SUSEP,
          órgão governamental responsável pela regulação das nossas atividades;
        </li>
        <li>
          Análise, melhorias e pesquisa: Podemos envolver um terceiro para fazer
          isso em nosso nome. Podemos compartilhar ou divulgar os resultados de
          tais pesquisas, inclusive para terceiros, de forma anônima e agregada.
          Utilizamos os seus dados pessoais para fins analíticos, para melhorar
          a experiência do utilizador e para melhorar a funcionalidade e a
          qualidade dos nossos serviços;
        </li>
        <li>
          Segurança, detecção e prevenção de fraudes: Usamos as informações, que
          podem incluir dados pessoais, para evitar fraudes e outras atividades
          ilegais ou infratores. Também usamos essas informações para investigar
          e detectar fraudes. Podemos usar dados pessoais para fins de avaliação
          e segurança de risco, incluindo a autenticação de usuários. Para esses
          fins, os dados pessoais podem ser compartilhados com terceiros,
          autoridades responsáveis pela aplicação da lei, conforme permitido
          pela lei aplicável e por consultores externos;
        </li>
        <li>
          Jurídico e conformidade: Em certos casos, precisamos usar as
          informações fornecidas, que podem incluir dados pessoais, para tratar
          e resolver disputas legais ou reclamações, para investigações
          regulatórias e conformidade, ou para aplicar acordos ou para cumprir
          com solicitações legais da aplicação da lei, na medida em que isso
          seja exigido por lei. Para atender uma intimação judicial ou
          apresentar uma defesa em processos judiciais, administrativos ou
          arbitrais.
        </li>
      </ul>
    </Container>
  );
};

export default DataUse;
