import { GlobalStyle } from "../../styles/global.styled";
import iconTrophy from "../../images/trophy.svg";
import { ContainerPaymentApproved } from "./styles";
import Sabemi from "../../Components/Sabemi";

const PaymentApproved = () => {
  return (
    <>
      <GlobalStyle />
      <ContainerPaymentApproved>
        <main>
          <img src={iconTrophy} alt="Seguro não foi aceito" />
          <h2>Falta pouco!</h2>
          <h2>
            No próximo dia útil autorize o débito do seu Seguro em seu banco e
            em até 48h seus benefícios serão ativados.
          </h2>
        </main>
        <footer>
          <Sabemi />
        </footer>
      </ContainerPaymentApproved>
    </>
  );
};

export default PaymentApproved;
