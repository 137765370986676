import {
  ButtonLink,
  PaymentStyled,
  SelectPayment,
  InputPayment,
} from "./payment.styled";
import {
  GlobalStyle,
  Container,
  ButtonText,
  StyledModal,
  ModalContent,
  ButtonPrimary,
} from "../../styles/global.styled";
import React, { Fragment } from "react";
import PackageNav from "../../Components/PackageNav/packageNav.component";
import iconUser from "../../images/name.svg";
import iconCalendar from "../../images/calendar-black.svg";
import iconCPF from "../../images/cpf.svg";
import iconAdressComp from "../../images/adresscomp.svg";
import { Checkout } from "../../Components/Checkout";
import { alertOpen } from "../packages/package.page";
import DataUse from "../../Components/DataUse";
import iconCEP from "../../images/map.svg";
import iconAdress from "../../images/adress.svg";
import iconLocation from "../../images/map-pin.svg";
import autoRestrito from "../../images/sabemi-produto/auto-restrito.png";
import funeral from "../../images/sabemi-produto/funeral.png";
import invalidez from "../../images/sabemi-produto/invalidez.png";
import morteAcidental from "../../images/sabemi-produto/morte-acidental.png";
import protecaoPessoal from "../../images/sabemi-produto/protecao-pessoal.png";
import residentalEletroassist from "../../images/sabemi-produto/residencial-eletroassist.png";
import banks from "../../services/banks.json";
import RolesText from "../../Components/RolesText";
import { useLead } from "../../hooks/lead";
import pdf from "../../documents/sabemi.pdf";
import pdfAssistencia from "../../documents/assistencia.pdf";
import { BrowserView, MobileView } from "react-device-detect";
import Sabemi from "../../Components/Sabemi";

export const PaymentPage = (props: any) => {
  const { lead, handleLead, handleEventLead } = useLead();

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    handleLead({
      check_bb: false,
      check_roles: false,
      payment_option: "0",
      bank_number: "",
      bank_account: "",
      bank_agency: "",
    });
  }, [handleLead]);

  const toggleModal = () => {
    setIsOpen(true);
  };

  return (
    <Fragment>
      <GlobalStyle />
      <PaymentStyled>
        <Container>
          <PackageNav
            lead={lead}
            color="#000"
            title="Chegamos na reta final!"
            active="Pagamento"
          />
          <div className="box-section">
            <h2>Selecione uma forma de pagamento e ative o seu seguro.</h2>
            <br />{" "}
            <SelectPayment>
              <select
                defaultValue="0"
                name="payment_option"
                onChange={handleEventLead}
              >
                <option disabled value={"0"}>
                  Escolha uma opção
                </option>

                <option value={"3"}>Cartão de Crédito</option>
                <option value={"1"}>Débito em Conta</option>
              </select>
            </SelectPayment>
            {lead.payment_option === "1" && (
              <InputPayment>
                <SelectPayment>
                  <select
                    defaultValue="0"
                    name="bank_number"
                    onChange={handleEventLead}
                  >
                    <option disabled value={"0"}>
                      Escolha um banco
                    </option>
                    {banks.map((bank: any) => {
                      return (
                        <option value={bank.COMPE} key={bank.COMPE}>
                          {bank.COMPE} | {bank.ShortName}
                        </option>
                      );
                    })}
                  </select>
                </SelectPayment>
                {lead.bank_number === "001" && (
                  <InputPayment>
                    <input
                      type="text"
                      placeholder="Agência - 9999-D"
                      className="agency"
                      name="bank_agency"
                      onChange={handleEventLead}
                      value={lead.bank_agency}
                    />

                    <input
                      type="text"
                      placeholder="Conta - 99999999-D"
                      className="account"
                      name="bank_account"
                      onChange={handleEventLead}
                    />
                    <span>Substitua o "X" pelo "0" quando houver</span>
                  </InputPayment>
                )}
                {lead.bank_number === "033" && (
                  <InputPayment>
                    <input
                      type="text"
                      placeholder="Agência - 9999"
                      className="agency"
                      name="bank_agency"
                      onChange={handleEventLead}
                    />

                    <input
                      type="text"
                      placeholder="Conta - 99999999-D"
                      className="account"
                      name="bank_account"
                      onChange={handleEventLead}
                    />
                  </InputPayment>
                )}
                {lead.bank_number === "104" && (
                  <InputPayment>
                    <input
                      type="text"
                      placeholder="Agência - 9999"
                      className="agency"
                      name="bank_agency"
                      onChange={handleEventLead}
                    />

                    <input
                      type="text"
                      placeholder="Conta - XXX99999999-D"
                      className="account"
                      name="bank_account"
                      onChange={handleEventLead}
                    />
                    <span>X: Operação</span>
                  </InputPayment>
                )}
                {lead.bank_number === "237" && (
                  <>
                    <input
                      type="text"
                      placeholder="Agência - 9999-D"
                      className="agency"
                      name="bank_agency"
                      onChange={handleEventLead}
                    />

                    <input
                      type="text"
                      placeholder="Conta - 9999999-D"
                      className="account"
                      name="bank_account"
                      onChange={handleEventLead}
                    />
                  </>
                )}
                {lead.bank_number === "341" && (
                  <>
                    <input
                      type="text"
                      placeholder="Agência - 9999"
                      className="agency"
                      name="bank_agency"
                      onChange={handleEventLead}
                    />

                    <input
                      type="text"
                      placeholder="Conta - 99999-D"
                      className="account"
                      name="bank_account"
                      onChange={handleEventLead}
                    />
                  </>
                )}
              </InputPayment>
            )}
          </div>
        </Container>

        <section className="revision-order">
          <Container>
            <h3>
              Vamos revisar e garantir que está tudo certo com o seu seguro?
            </h3>
            <h4>Coberturas</h4>
            <ul>
              <li>
                <i>
                  <img src={morteAcidental} alt="Morte Acidental" />
                </i>{" "}
                <p>
                  Morte Acidental{" "}
                  {lead.selected_product === "Torcedor Protegido" && (
                    <span>R$ 10.000</span>
                  )}
                  {lead.selected_product === "Torcedor Protegido Plus" && (
                    <span>R$ 20.000</span>
                  )}
                </p>
              </li>
              <li>
                <img src={invalidez} alt="invalidez" />
                <p>
                  Invalidez Permanente Total ou Parcial por Acidente (IPA)
                  {lead.selected_product === "Torcedor Protegido" && (
                    <span>R$ 10.000</span>
                  )}
                  {lead.selected_product === "Torcedor Protegido Plus" && (
                    <span>R$ 20.000</span>
                  )}
                </p>
              </li>
            </ul>

            <h4>Assistências</h4>
            <ul>
              <li>
                <i>
                  <img src={funeral} alt="Assistência funeral" />
                </i>
                <p>Assistência Funeral</p>
              </li>
              <li>
                <i>
                  <img src={protecaoPessoal} alt="Proteção pessoal" />
                </i>
                <p>Proteção Pessoal</p>
              </li>

              <li>
                <i>
                  <img src={autoRestrito} alt="Auto Restrito" />
                </i>
                <p>Auto Restrito</p>
              </li>

              {lead.selected_product === "Torcedor Protegido Plus" && (
                <li>
                  <i>
                    <img
                      src={residentalEletroassist}
                      alt="Residencial Eletroassist"
                    />
                  </i>
                  <p>
                    Residencial <br />
                    Eletroassist
                  </p>
                </li>
              )}
            </ul>

            <h4>Dados pessoais</h4>

            <div className="data-list">
              <fieldset>
                <h5>Informações do segurado</h5>
                <p>
                  <i>
                    <img src={iconUser} alt="Segurado" />
                  </i>
                  {lead.name}
                </p>

                <p>
                  <i>
                    <img src={iconCPF} alt="CPF" />
                  </i>
                  {lead.cpf}
                </p>

                <p>
                  <i>
                    <img src={iconCalendar} alt="Data" />
                  </i>
                  {lead.birthday}
                </p>
              </fieldset>
              <fieldset>
                <h5>Informações residenciais</h5>
                <p>
                  <i>
                    <img src={iconCEP} alt="Localização" />
                  </i>
                  {lead.address_cep}
                </p>
                <p>
                  <i>
                    <img src={iconAdress} alt="Endereço" />
                  </i>
                  {lead.address_street}
                </p>
                {lead.address_complement && (
                  <p>
                    <i>
                      <img src={iconAdressComp} alt="Complemento" />
                    </i>
                    {lead.address_complement}
                  </p>
                )}
                <p>
                  <i>
                    <img src={iconLocation} alt="Número" />
                  </i>
                  {lead.address_number}
                </p>
              </fieldset>
            </div>
            <div className="privacy">
              <ButtonText onClick={() => alertOpen(<DataUse />)}>
                Como meus dados são usados?
              </ButtonText>
              <BrowserView>
                <p>
                  Ao finalizar a compra, declaro que li e concordo com as{" "}
                  <a target="_blank" rel="noreferrer" href={pdf}>
                    Condições Gerais
                  </a>{" "}
                  do produto e{" "}
                  <a target="_blank" rel="noreferrer" href={pdfAssistencia}>
                    Manual de Assistências
                  </a>{" "}
                  e confirmo que todas informações fornecidas são verdadeiras.
                </p>
              </BrowserView>
              <MobileView>
                <p>
                  Ao finalizar a compra, declaro que li e concordo com as{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    download="sabemi-condições-gerais.pdf"
                    href={pdf}
                  >
                    Condições Gerais
                  </a>{" "}
                  do produto e{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    download="sabemi-assistencia.pdf"
                    href={pdfAssistencia}
                  >
                    Manual de Assistências
                  </a>{" "}
                  e confirmo que todas informações fornecidas são verdadeiras.
                </p>
              </MobileView>

              <label>
                <input
                  type="checkbox"
                  onChange={handleEventLead}
                  name="check_roles"
                  checked={lead.check_roles}
                />
                <p>
                  Li e concordo com as{" "}
                  <ButtonLink onClick={toggleModal}>regras</ButtonLink> deste
                  seguro, declaração de veracidade das informações e
                  conhecimento das Condições Gerais.
                </p>
              </label>

              {lead.payment_option === "1" && lead.bank_number === "001" && (
                <label>
                  <input
                    type="checkbox"
                    name="check_bb"
                    checked={lead.check_bb}
                    onChange={handleEventLead}
                  />
                  <p>
                    Confirmo os dados acima chamados e AUTORIZO a cobrança do
                    Seguro Sabemi, através da recorrência no Débito em Conta.
                  </p>
                </label>
              )}
            </div>
          </Container>
        </section>
      </PaymentStyled>
      <StyledModal isOpen={isOpen}>
        <ModalContent>
          <RolesText />
          <div>
            <ButtonPrimary onClick={() => setIsOpen(false)}>
              Fechar
            </ButtonPrimary>
          </div>
        </ModalContent>
      </StyledModal>
      <Container>
        <div className="box-section">
          <Checkout
            leadId={lead.leadId}
            selected_product={lead.selected_product}
            formData={handleEventLead}
          />

          <Sabemi />
        </div>
      </Container>
    </Fragment>
  );
};
