import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import Home from "./pages/home/home.pages";
import Package from "./pages/packages/package.page";
import PlansPage from "./pages/plans/plans.page";
import MyDataPage from "./pages/mydata/mydata.page";
import { PaymentPage } from "./pages/payment/payment.page";
import PaymentPending from "./pages/PaymentPending";
import PaymentApproved from "./pages/PaymentApproved";
import PaymentFail from "./pages/PaymentFail";
import LinkCode from "./pages/LinkCode";

import { useLead } from "./hooks/lead";
import Reports from "./pages/Reports";

const PrivateRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  const { lead } = useLead();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        lead.leadId ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Home} />
      <PrivateRoute path="/planos" component={Package} />
      <PrivateRoute path="/detalhes" component={PlansPage} />
      <PrivateRoute path="/dados" component={MyDataPage} />
      <PrivateRoute path="/pagamento" component={PaymentPage} />
      <Route path="/falha" component={PaymentFail} />
      <Route path="/pendente" component={PaymentPending} />
      <Route path="/sucesso" component={PaymentApproved} />
      <Route path="/relatorio" component={Reports} />
      <Route path="/oferta" component={LinkCode} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
