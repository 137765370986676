import { GlobalStyle } from "../../styles/global.styled";
import { ContainerPaymentApproved } from "./styles";
import Sabemi from "../../Components/Sabemi";
import QRCode from "react-qr-code";

const LinkCode = () => {
  /* const onImageCownload = () => {
    const svg: Node = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  }; */

  return (
    <>
      <GlobalStyle />
      <ContainerPaymentApproved>
        <main>
          <h2>
            O <strong>cuidado</strong> que você merece. <br /> A{" "}
            <strong>segurança</strong> que você procura.
          </h2>
          <p>
            Cotação 100% digital, personalizada e sem burocracia. <br />
          </p>{" "}
          <a href="http://ceara.ofertadigital.in">
            http://ceara.ofertadigital.in
          </a>{" "}
          <div className="qrcode">
            <QRCode
              bgColor="#fff"
              fgColor="#000"
              size={180}
              level="L"
              value="http://ceara.ofertadigital.in"
              title="Oferta Digital"
            />
          </div>{" "}
          {/*  <input type="button" value="Download QR" onClick={onImageCownload} /> */}
        </main>
        <footer>
          <Sabemi />
        </footer>
      </ContainerPaymentApproved>
    </>
  );
};

export default LinkCode;
