import styled from "styled-components";

export const OrderSummaryStyled = styled.div`
  background: #000000;
  display: flex;
  justify-content: space-between;
  padding: 35px 64px;
  align-items: center;
  //border-radius: 20px 20px 0px 0px;

  @media (max-width: 600px) {
    display: block;
    padding: 35px 14px;
    text-align: center;
    margin: 0 15px;
  }

  button {
    width: 228px;
    height: 55px;
    left: 1001px;
    top: 2923px;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 12px;
    background: none;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    transition: ease 0.2s;
    cursor: pointer;

    &:hover {
      background: #fff;
      color: #000000;
    }
  }

  .final-cost {
    text-align: center;

    @media (max-width: 600px) {
      margin: 30px 0;
    }

    span {
      font-size: 12px;
      font-weight: bold;
      color: #fff;
    }

    small {
      font-size: 18px;
    }
  }

  p {
    color: #fff;
    font-size: 55px;
    font-weight: 800;
    margin: 0;
    line-height: 59px;

    @media (max-width: 600px) {
      font-size: 40px;
      line-height: 30px;
    }
  }

  ul {
    display: flex;

    @media (max-width: 600px) {
      justify-content: space-around;
    }

    li {
      color: #fff;
      font-size: 45px;
      font-weight: 800;
      text-align: center;
      margin: 0 20px;

      @media (max-width: 600px) {
        margin: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0%;
      }

      span {
        font-size: 12px;
        display: block;
        font-weight: bold;
      }
    }
  }
`;
