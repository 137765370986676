import styled from "styled-components";

const Container = styled.div`
  padding: 40px;

  text-align: left;
  ul {
    margin: 10px;
    list-style: disc;
    padding: 10px;

    li {
      margin: 10px;
    }
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

export { Container };
