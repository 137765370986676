import { GlobalStyle } from "../../styles/global.styled";
import iconTrophy from "../../images/trophy.svg";
import { ContainerPaymentApproved } from "./styles";
import Sabemi from "../../Components/Sabemi";

const PaymentApproved = () => {
  return (
    <>
      <GlobalStyle />
      <ContainerPaymentApproved>
        <main>
          <img src={iconTrophy} alt="Seguro não foi aceito" />
          <h2>Pagamento realizado com sucesso. </h2>
          <h2>
            Em até 48h seus benefícios serão ativados. Em caso de dúvida entre
            em contato.
          </h2>
        </main>
        <footer>
          <Sabemi />
        </footer>
      </ContainerPaymentApproved>
    </>
  );
};

export default PaymentApproved;
