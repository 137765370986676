import React, { Fragment } from "react";
import {
  RegisterStyle,
  InputRowStyled,
  InputLabel,
  InputStyled,
  InputSelect,
} from "./register.styled";
import mailIcon from "../../images/mail.svg";
import phoneIcon from "../../images/phone.svg";
import calendarIcon from "../../images/calendar.svg";
import jobIcon from "../../images/job.svg";
import { getProfessions } from "../../services/api";
import { useHistory } from "react-router";
import MaskedInput from "react-text-mask";
import { isValid, parse, differenceInDays, differenceInYears } from "date-fns";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useLead } from "../../hooks/lead";

const Register = (props: any) => {
  const history = useHistory();
  const [professions, setProfessions] = React.useState([] as any);
  const { lead, postLead } = useLead();

  const MySwal = withReactContent(Swal);

  React.useEffect(() => {
    //console.log(lead);
    getProfessions().then((data) => {
      let professions2: any = [];

      data.map((profession: any) => {
        return professions2.push({
          value: profession._id,
          label: profession.title,
          key: profession._id,
        });
      });
      setProfessions(professions2);
    });
  }, []);
  const alertOpen = (msg: any) => {
    MySwal.fire({
      html: `<h2>Verifique os dados informados</h2>
      <p>${msg}</p>`,
      confirmButtonColor: "black",
      confirmButtonText: "Fechar",
      allowOutsideClick: true,
      showCloseButton: true,

      customClass: {},
    });
  };

  function validateCellphone(cellphone: any) {
    const regex = /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/;
    return regex.test(cellphone);
  }

  function validateEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const submitHandle = async (e: any) => {
    e.preventDefault();

    const data = {
      email: e.target.email?.value.trim(),
      cellphone: e.target.cellphone?.value,
      birthday: e.target.birthday?.value,
      sex: e.target.sex?.value,
      profession: e.target.profession?.value,
    };

    const testDate = differenceInDays(
      new Date(),
      parse(data.birthday, "dd/MM/yyyy", new Date())
    );

    //console.log(testDate);

    const yearsOld = differenceInYears(
      new Date(),
      parse(data.birthday, "dd/MM/yyyy", new Date())
    );

    //console.log(yearsOld);
    if (!validateEmail(data.email)) {
      alertOpen("Email inválido!");
    } else if (data.cellphone.replace(/\D/g, "").length !== 11) {
      alertOpen("Telefone inválido!");
    } else if (!validateCellphone(data.cellphone.replace(/\D/g, ""))) {
      alertOpen("Telefone inválido!");
    } else if (!isValid(parse(data.birthday, "dd/MM/yyyy", new Date()))) {
      alertOpen("Data de nascimento inválida!");
    } else if (yearsOld > 80) {
      alertOpen("Idade não permitida.");
    } else if (yearsOld < 18) {
      alertOpen("Idade não permitida.");
    } else if (testDate < 0) {
      alertOpen("Data de nascimento inválida!");
    } else if (data.sex === "") {
      alertOpen("Gênero não informado.");
    } else if (data.profession === "profession") {
      alertOpen("Profissão não selecionada.");
    } else {
      await postLead(data);
      history.push("/planos");
    }
  };

  return (
    <Fragment>
      {professions[0] && (
        <RegisterStyle>
          <form onSubmit={submitHandle} name="register">
            <InputRowStyled icon={mailIcon}>
              <InputLabel htmlFor="email">E-mail</InputLabel>
              <MaskedInput
                mask={false}
                // type="email"
                name="email"
                id="email"
                defaultValue={lead?.email || ""}
                placeholder="E-mail"
              />
            </InputRowStyled>
            <InputRowStyled icon={phoneIcon}>
              <InputLabel htmlFor="phone">Telefone</InputLabel>
              <MaskedInput
                mask={[
                  "(",
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                type="phone"
                id="phone"
                //placeholder="(00) 0000-0000"
                placeholder="Celular"
                name="cellphone"
                defaultValue={lead.cellphone}
              />
            </InputRowStyled>

            <InputRowStyled icon={calendarIcon}>
              <InputLabel htmlFor="date">Data de Nascimento</InputLabel>
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                //type="date"
                name="birthday"
                id="birthday"
                placeholder="Nascimento"
                defaultValue={lead.birthday}
              />
            </InputRowStyled>

            <div className="select-genre">
              <InputRowStyled>
                <div className="genre male">
                  <InputLabel htmlFor="male">Masculino</InputLabel>
                  <InputStyled
                    type="radio"
                    defaultChecked={lead.sex === "M" ? true : false}
                    id="male"
                    name="sex"
                    value="M"
                  ></InputStyled>
                  <span className="checkmark"></span>
                </div>
              </InputRowStyled>
              <InputRowStyled>
                <div className="genre female">
                  <InputLabel htmlFor="female">Feminino</InputLabel>
                  <InputStyled
                    type="radio"
                    defaultChecked={lead.sex === "F"}
                    id="female"
                    name="sex"
                    value="F"
                  ></InputStyled>
                  <span className="checkmark"></span>
                </div>
              </InputRowStyled>
            </div>

            <InputRowStyled icon={jobIcon}>
              <InputLabel>Profissão</InputLabel>
              <InputSelect>
                <select
                  placeholder="Profissão"
                  name="profession"
                  defaultValue={lead.profession}
                >
                  <option id="0" key="0" value="profession" disabled>
                    Profissão
                  </option>
                  {professions.map((profession: any) => {
                    return (
                      <option
                        id={profession.id}
                        value={profession.label}
                        key={profession.key}
                      >
                        {profession.label}
                      </option>
                    );
                  })}
                </select>
              </InputSelect>
            </InputRowStyled>

            <InputStyled
              type="submit"
              value="Escolher meu seguro"
            ></InputStyled>
          </form>
        </RegisterStyle>
      )}
    </Fragment>
  );
};

export default Register;
