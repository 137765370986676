import styled from "styled-components";

export const Container = styled.div`
  background: white;
  max-width: 1024px;
`;

export const TextJustify = styled.p`
  text-align: justify;
  text-justify: inter-word;
`;
