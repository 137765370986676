import axios from "axios";
import Swal from "sweetalert2";
import { Dispatch } from "../redux/store";
import getLocation from "./getIp";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
export const createLead = async (data: any) => {
  try {
    Dispatch({
      type: "show_loader",
      show: true,
    });
    const response = await api.post(`/leads`, {
      ...data,
      subAccountId: process.env.REACT_APP_ID_SUBACCOUNT,
    });
    Dispatch({
      type: "show_loader",
      show: false,
    });

    return response.data;
  } catch (e: any) {
    Swal.fire({
      html: `<div>
      <h3>Dados inválidos</h3>
      <ul>
      ${e.response.data.message
        .map(function (msg: any) {
          return `<li key=${msg}>${msg}</li>`;
        })
        .join("")}
        </ul>
        </div>`,
      icon: "error",
      confirmButtonColor: "#000",
      confirmButtonText: "Fechar",
    });
    Dispatch({
      type: "show_loader",
      show: false,
    });
    return false;
  }
};

export const updateLead = async (id: any, data: any) => {
  try {
    Dispatch({
      type: "show_loader",
      show: true,
    });

    const response = await api.put(`/leads/${id}`, data);
    Dispatch({
      type: "show_loader",
      show: false,
    });

    return response.data;
  } catch (e) {
    console.log(e);
    Dispatch({
      type: "show_loader",
      show: false,
    });
    return false;
  }
};

export const getProfessions = async () => {
  return await api
    .get("/professions")
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export async function updateEvent(data: any) {
  try {
    console.log(data);
    const response = await api.post(`/events`, {
      ...data,
      subAccountId: process.env.REACT_APP_ID_SUBACCOUNT,
    });
    return response.data.eventId;
  } catch (e) {
    console.log(e);
  }
}

export async function getReport(type: string) {
  try {
    Dispatch({
      type: "show_loader",
      show: true,
    });
    const res = await api.get(`/leads/${type}`, {
      params: { accounts: process.env.REACT_APP_ID_SUBACCOUNT },
      responseType: "blob",
    });
    Dispatch({
      type: "show_loader",
      show: false,
    });
    return res.data;
  } catch (e) {
    Dispatch({
      type: "show_loader",
      show: false,
    });
    console.log(e);
    return false;
  }
}

export async function getUrlPagamento(data: any) {
  Dispatch({
    type: "show_loader",
    show: true,
  });
  try {
    const dataLocation = await getLocation();
    // console.log(dataLocation);
    const res = await api.post(`/leads/contract/${data.leadId}`, {
      ...data,
      ...dataLocation,
    });
    Dispatch({
      type: "show_loader",
      show: false,
    });
    return res.data;
  } catch (e) {
    Dispatch({
      type: "show_loader",
      show: false,
    });

    return false;
  }
}
