import React, { Fragment } from "react";
import { BrowserView } from "react-device-detect";
import logo from "../../images/brasao-ceara.png";

import Sabemi from "../Sabemi";

type IIntroProps = {
  setForYou: React.Dispatch<React.SetStateAction<boolean>>;
};

const Intro: React.FC<IIntroProps> = ({ setForYou }: IIntroProps) => {
  return (
    <Fragment>
      <section className="intro">
        <header className="App-header">
          <h1>
            <img src={logo} alt="Logo" />
          </h1>
        </header>

        <div className="text-content">
          <h2>
            O <strong>cuidado</strong> que você merece.
            <span>
              A <strong>segurança</strong> que você procura.
            </span>
          </h2>
          <p>Cotação 100% digital, personalizada e sem burocracia.</p>{" "}
        </div>

        <BrowserView>
          <Sabemi disableLogo enableFooter />
        </BrowserView>
      </section>
    </Fragment>
  );
};

export default Intro;
