import styled from "styled-components";
import iconSecurity from "../../images/security.svg";

const LinkText = styled.a`
  color: #000;
  border: none;
  background-color: inherit;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
`;
const FooterLinksStyled = styled.div`
  padding-bottom: 30px;
  padding-left: 35px;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  footer ul {
    margin: 0 auto;
    margin-bottom: 10px;
    display: flex;

    font-size: 14px;
    padding: 0;
    color: #000;
    list-style: none;
  }

  footer ul li {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #fff;

    @media (max-width: 600px) {
      border: 0;
      margin: 0 auto;
      padding: 0;
    }
  }

  footer ul li:last-child {
    border: 0;
  }

  footer ul li a {
    color: #000;
    text-decoration: none;

    @media (max-width: 600px) {
      color: #fff;
      padding: 8px;
      display: block;
    }
  }

  footer ul li:first-child {
    background: url(${iconSecurity}) no-repeat;
    font-weight: bold;
    left: 10px;
    padding: 1px 10px 1px 23px;
    margin: 0 auto;
    @media (max-width: 600px) {
      padding: 10px;
      margin: 10px auto;
      background: none;
    }
  }
  footer {
    ul {
      @media (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    li {
      border: 0;
      a {
        color: #fff;
      }
    }
  }

  @media (max-width: 600px) {
    display: block;
    text-align: center;
    padding: 10px;
  }
`;
export { FooterLinksStyled, LinkText };
