import React, { Fragment } from "react";
import { FooterLinksStyled } from "./footerLinks.styled";
import {
  ButtonText,
  ButtonTextBold,
  GlobalStyle,
} from "../../styles/global.styled";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import DataUse from "../DataUse";
import Privacy from "../Privacy";

const MySwal = withReactContent(Swal);

const FooterLinks = () => {
  const alertOpen = (Component: any) => {
    MySwal.fire({
      html: Component,
      grow: "fullscreen",
      confirmButtonColor: "black",
      confirmButtonText: "Fechar",
      allowOutsideClick: true,
      showCloseButton: true,
      showClass: {
        backdrop: "", // disable backdrop animation
        popup: "", // disable popup animation
        icon: "", // disable icon animation
      },
      hideClass: {
        popup: "",
      },
      customClass: {},
    });
  };

  return (
    <Fragment>
      <GlobalStyle />
      <FooterLinksStyled>
        <footer className="menuLinks">
          <ul>
            <li>
              <ButtonTextBold onClick={() => alertOpen(<DataUse />)}>
                Como meus dados são usados?
              </ButtonTextBold>
            </li>
            <li>
              <ButtonText onClick={() => alertOpen(<Privacy />)}>
                {" "}
                Política de privacidade
              </ButtonText>
            </li>
            {/* <li>
                <ButtonText>
                  {" "}
                  <a href="mailto: sac@sabemi.com.br">Contato</a>
                </ButtonText>{" "}
              </li> */}
          </ul>
        </footer>

        {/*  <MobileView viewClassName="footer-mobile">
          <img src={logo} alt="Bria" />
          <footer className="menuLinks">
            <ul>
              <li>
                <button onClick={() => alertOpen(<DataUse />)}>
                  Como meus dados são usados?
                </button>
              </li>
              <li>
                <button onClick={() => alertOpen(<Privacy />)}>
                  Política de privacidade
                </button>
              </li>
              <li>
                <a href="null">Contato</a>
              </li>
            </ul>
          </footer>
        </MobileView> */}
      </FooterLinksStyled>
    </Fragment>
  );
};

export default FooterLinks;
