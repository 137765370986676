import ReactDOM from "react-dom";
import { Store } from "./redux/store";
import { Provider } from "react-redux";
import { ModalProvider } from "styled-react-modal";
import Routes from "./routes";
import Loader from "./Components/loader";
import AppProvider from "./hooks";

ReactDOM.render(
  <Provider store={Store}>
    <AppProvider>
      <ModalProvider>
        <Loader></Loader>
        <Routes />
      </ModalProvider>
    </AppProvider>
  </Provider>,
  document.getElementById("root")
);
