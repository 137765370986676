import styled, { css } from "styled-components";
import iconArrow from "../../images/arrow.svg";
import iconMale from "../../images/male.svg";
import iconMaleDisable from "../../images/male-disabled.svg";
import iconFemale from "../../images/female.svg";
import iconFemaleDisable from "../../images/female-disabled.svg";
import iconYou from "../../images/you.svg";
import iconYouActive from "../../images/you-active.svg";
import iconTabActive from "../../images/tab-active.svg";
import iconTabYouDisabled from "../../images/tab-inactive.svg";
import iconBusiness from "../../images/business.svg";
import iconBusinessActive from "../../images/business-active.svg";

const RegisterContent = styled.main`
  ${(props: { forYou: boolean }) =>
    props.forYou
      ? css`
          .select-profile {
            position: absolute;
            right: -212px;
            top: 80px;

            @media (max-width: 600px) {
              position: relative;
              top: 0;
              right: 0;
              text-align: center;
              display: flex;
              justify-content: center;
              margin-bottom: -48px;
            }

            @media (min-width: 1420px) and (max-width: 2560px) {
              top: 0px;
            }
          }

          .select-profile ul {
            padding: 0;
            list-style: none;

            @media (max-width: 600px) {
              transform: rotate(90deg);
              margin: 0;
              width: 60px;
              display: flex;
              flex-wrap: wrap;
              flex-direction: column-reverse;
            }
          }

          .select-profile ul li {
            color: #fff;
            font-size: 25px;
            background: url(${iconTabYouDisabled}) no-repeat center left;
            height: 70px;
            display: flex;
            align-items: center;
            position: relative;
            width: 270px;

            @media (max-width: 600px) {
              width: auto;
              height: 76px;
            }
          }

          .select-profile ul li a {
            z-index: 99999;
            position: relative;
            color: rgb(255 255 255 / 30%);
            text-decoration: none;
            padding-left: 80px;

            @media (max-width: 600px) {
              padding-left: 0;
              color: rgb(47 5 77 / 100%);
              transform: rotate(270deg);
              font-size: 15px;
              margin-bottom: -150px;
              width: 100px;
              height: 54px;
            }
          }

          .select-profile ul li:last-child:after {
            content: "";
            display: block;
            width: 34px;
            height: 35px;
            left: 15px;
            top: 22px;
            background: url(${iconBusiness}) no-repeat;
            position: absolute;
          }

          .select-profile ul li:hover {
            background: url(${iconTabActive}) no-repeat center left;
          }

          .select-profile ul li:hover a {
            color: #fff;
          }

          .select-profile ul li:last-child:hover:after {
            background: url(${iconBusinessActive}) no-repeat;
          }

          .select-profile ul li:first-child {
            background: url(${iconTabActive}) no-repeat center left;
            font-weight: bold;
          }
          .select-profile ul li:first-child a {
            color: #fff;

            @media (max-width: 600px) {
              display: none;
              color: rgb(47 5 77 / 100%);
              transform: rotate(270deg);
              font-size: 15px;
              margin-bottom: -158px;
            }
          }

          .select-profile ul li:first-child:after {
            content: "";
            display: block;
            width: 34px;
            height: 35px;
            top: 18px;
            left: 14px;
            background: url(${iconYou}) no-repeat;
            position: absolute;

            @media (max-width: 600px) {
              transform: rotate(270deg) scale(0.9);
              top: 20px;
            }
          }
          @media (max-width: 600px) {
            .select-profile ul li a {
              font-size: 15px;
              margin-bottom: 160px;
              width: 75px;
              height: 54px;
            }
            .select-profile ul li:last-child:after {
              transform: rotate(270deg);
              top: 19px;
              left: 19px;
            }
          }
        `
      : css`
          .select-profile ul li:first-child:after {
            content: "";
            display: block;
            width: 34px;
            height: 35px;
            top: 18px;
            left: 14px;
            background: url(${iconYouActive}) no-repeat;
            position: absolute;
            opacity: 0.3;
          }
          .select-profile ul li:first-child {
            background: url(${iconTabYouDisabled}) no-repeat center left;
          }
          .select-profile ul li:last-child {
            background: url(${iconTabActive}) no-repeat center left;
            font-weight: bold;
          }
          .select-profile ul li:after:last-child {
            background: url(${iconBusiness}) no-repeat center left;
            font-weight: bold;
          }
          @media (max-width: 600px) {
            .select-profile ul li:last-child a {
              color: #fff;
              display: none;
            }
          }
          .select-profile ul li:last-child:after {
            content: "";
            display: block;
            width: 34px;
            height: 35px;
            top: 22px;
            left: 15px;
            background: url(${iconBusinessActive}) no-repeat;
            position: absolute;
          }
        `}
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  section {
    // width: 50%;
    height: auto;
    box-sizing: border-box;
    padding-bottom: 0;

    @media (max-width: 1023px) {
      width: 100%;
      height: auto;
    }
  }

  .intro {
    padding: 35px;
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;

    @media (max-width: 600px) {
      padding: 53px 53px 0px 53px;
      width: 100%;
    }
  }

  .intro h2 {
    font-size: 54px;
    line-height: 64px;
    font-weight: normal;
    margin-bottom: 20px;

    @media (max-width: 600px) {
      font-size: 28px;
      line-height: 33px;
      margin: 0;
    }
  }

  .intro h2 span {
    display: block;
  }

  .text-content {
    max-width: 83%;
    text-align: left;

    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .text-content p {
    font-size: 21px;
    color: #676767;
    max-width: 80%;
  }
`;

const SectionForm = styled.section`
  width: 40%;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
  //position: relative;
  margin: 0 auto;

  h2 {
    color: #fff;
    font-size: 34px;
    margin: 0;
    padding-bottom: 50px;
  }

  @media (max-width: 600px) {
    padding: 40px 53px;
  }
  @media (max-width: 600px) {
    padding: 40px 53px;
  }

  form {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 354px;
    margin-bottom: 20px;

    ::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    :-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
    input::placeholder,
    input::-webkit-input-placeholder,
    input::-ms-input-placeholder,
    ::placeholder,
    select {
      color: rgba(255, 255, 255, 0.6);
    }

    select,
    input {
      background: none;
      box-shadow: none;
      border: 0;
      width: 100%;
      box-sizing: border-box;
      font-size: 20px;
      border-bottom: 2px solid #fff;
      padding-bottom: 13px;
      margin-bottom: 40px;
      color: #fff;
      padding-left: 40px;
    }

    select,
    .section-form form input[type="date"] {
      color: rgba(255, 255, 255, 0.6);
      width: 100%;
      box-sizing: border-box;
    }
    input::-webkit-calendar-picker-indicator {
      display: none;
    }

    input:focus {
      outline: none;
      border-bottom: 2px solid #eee;
    }

    input[type="submit"] {
      border: 2px solid #ffffff;
      box-sizing: border-box;
      border-radius: 12px;
      height: 54px;
      padding: 0;
      color: #fff;
      background: url(${iconArrow}) no-repeat 310px;
    }

    input[type="radio"] {
      display: none;
    }

    .row-input {
      position: relative;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .genre {
      margin-bottom: 20px;
      label {
        padding-left: 48px;
        font-size: 20px;
        font-weight: bold;
        height: 33px;
        display: block;
        padding-top: 5px;
        cursor: pointer;
        color: rgb(255 255 255 / 60%);
      }

      input:checked > label {
        color: #f31;
      }
      .select-genre {
        margin-bottom: 20px;
      }
    }

    .male input:checked ~ .checkmark {
      background: #fff url(${iconMale}) center center no-repeat;
      background-size: 27px;
    }

    .male input:checked label {
      color: #fff;
    }

    .male .checkmark {
      background: url(${iconMaleDisable}) center center no-repeat;
      background-size: 27px;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 36px;
      width: 36px;
      border-radius: 50%;
      pointer-events: none;
      border: 2px solid rgb(255 255 255 / 60%);
      :after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    .female input:checked ~ .checkmark {
      background: #fff url(${iconFemale}) center center no-repeat;
      background-size: 16px;
    }

    .female input:checked label {
      color: #fff;
    }

    .female .checkmark {
      background: url(${iconFemaleDisable}) center center no-repeat;
      background-size: 16px;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;
export { RegisterContent, SectionForm };
